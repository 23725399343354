<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner">
        <h1>香港暴乱</h1>
        <p><span>报告于2019-08-13 19:33:57自动生成</span></p>
      </div>
      <div class="btn_tab" id="emotion-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a class="selected cur-p" @click="switchTab(0, true)">媒体观点分析</a>
              <a class="cur-p" @click="switchTab(1, true)">政府观点分析</a>
              <a class="cur-p" @click="switchTab(2, true)">微博观点分析</a>
              <a class="cur-p hide" @click="switchTab(3, true)">微信观点分析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 媒体观点分析 -->
        <a name="mtgd" id="mtgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">媒体观点分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在2019-07-01 00:00:00至2019-08-05 23:59:59期间的92125条信息中，共125412条信息来源于媒体，媒体报道中负面情感占主导，以下将罗列各来源的重要观点。
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体类型类别</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="state_sum"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">媒体观点情绪分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="domestic_dis"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8" class="hide">
              <el-card class="box-card">
                <h3 class="mod-title">国外媒体观点情绪分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data['oversea_dis'].negative === 0 && data['oversea_dis'].positive === 0">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="oversea_dis" v-show="data['oversea_dis'].negative >= 1 || data['oversea_dis'].positive >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">媒体观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['domestic_media_analyse'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['domestic_media_analyse'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['domestic_media_analyse']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmt_sxbh">
            <h3 class="mod-title">媒体观点时序变化</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20" v-if="!data['opinion_domestic_media_chronological'].isIncubation">
              <!-- 时间轴 -->
              <div class="echarts_empty" v-show="data['opinion_domestic_media_chronological'].data.length < 1">
                <div class="img"></div>
              </div>
              <div class="demo" v-show="data['opinion_domestic_media_chronological'].data.length >= 1">
                <div class="main-timeline">
                  <div class="timeline" v-for="(row, index) in data['opinion_domestic_media_chronological'].data" :key="index" v-if="index < 20">
                    <div class="timeline-content">
                      <div class="circle">
                        <h5 class="title"><a :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a></h5>
                        <p class="xgbds"><span>相关报导数 ： {{ row.count }}条</span><span>信息占比 ： {{ row.percent }}%</span></p>
                        <p class="source">
                          <a v-for="(media, index) in row.media" :key="index">
                            <var>{{ media }}</var> <span>|</span>
                          </a>
                        </p>
                      </div>
                      <div class="content">
                        <span class="year">{{ row.date }}</span>
                        <div class="icon"><span></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-lr-20" v-else>
              <div class="w-100 h-400" id="opinion_domestic_media_chronological"></div>
              <div class="demo2">
                <div class="main-timeline2" v-for="(row, index) in data['opinion_domestic_media_chronological'].data.timeline" :key="index">
                  <div class="item" v-if="row.type === 'point'">
                    <div class="main-title">
                      <div class="point">
                        <p v-for="(point, index) in row.points" :key="index">
                          <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                          <span>{{ point }}</span>
                        </p>
                      </div>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="circle_title">
                            <h4>
                              <span class="mg" v-if="row.article.emotion < 50">敏感</span>
                              <a :href="row.article.source_url" target="_blank">{{ row.article.title }}</a>
                            </h4>
                            <p><span>相关报导数：{{ row.article.total }}条</span><span>信息占比：{{ row.article.percent }}%</span></p>
                            <dl>
                              <dd v-for="(media, index) in row.article.medias" :key="index">
                                <a>{{ media }}</a><span class="bd">|</span>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div class="content">
                          <span class="year">{{ row.date }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item item_title" v-else>
                    <div class="main-title">
                      <h3>{{ row.state }}</h3>
                    </div>
                    <div class="timeline">
                      <div class="timeline-content bd_n">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(article, index) in row.articles" :key="index">
                                <span class="num bg-555" v-if="index == 0">1</span>
                                <span class="num bg-689" v-else-if="index == 1">2</span>
                                <span class="num bg-8ea" v-else-if="index == 2">3</span>
                                <a class="mod-list-title2" :href="article.source_url" target="_blank">{{ article.title }}</a>
                                <a class="site"><span>|</span>{{ article.media }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">媒体重大负面观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_domestic_media_neg'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['opinion_domestic_media_neg'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_domestic_media_neg']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">主要媒体观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_domestic_main_media'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_domestic_main_media'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_domestic_main_media']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_domestic_main_media'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_domestic_main_media']" :key="index" v-if="index <= 5 && index > 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20 hide">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">国外媒体观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_oversea'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['opinion_oversea'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_oversea']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20 hide">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">主要国外媒体观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_oversea_main_media'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_oversea_main_media'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_oversea_main_media']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_oversea_main_media'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_oversea_main_media']" :key="index" v-if="index > 2 && index <= 5">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index">
                          <a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a>
                        </li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 政府观点分析 -->
        <a name="zfgd" id="zfgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">政府观点分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在2019-07-01 00:00:00至2019-08-05 23:59:59期间的92125条信息中， 893条来自政府网站， 其中多为负面观点。 以下将罗列来自政府网站的重要观点。
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">政府观点情绪分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_gov_dis"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">主要政府观点来源分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data.opinion_gov_main.type.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_gov_main" v-show="data.opinion_gov_main.type.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card gnmtgd">
            <h3 class="mod-title">政府网站观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_gov_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list" v-show="data['opinion_gov_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关报导数</span><span class="tag">信息占比</span><span class="source">代表媒体</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_gov_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="tag">{{ row.count }}条</span>
                    <span class="tag">{{ row.percent }}%</span>
                    <p class="source">
                      <a v-for="(media, index) in row.media" :title="media"><var>{{ media }}</var> <span>|</span></a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">主要政府网站观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_gov_main_media'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_gov_main_media'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_gov_main_media']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_gov_main_media'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_gov_main_media']" :key="index" v-if="index > 2 && index <= 5">
                    <div class="mtgd_je">
                      <h5><a>{{ row.media }}</a></h5>
                      <p>相关报导数：<span>{{ row.count }}条</span></p>
                      <ul>
                        <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 微博观点分析 -->
        <a name="wbgd" id="wbgd"></a>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博观点分析</h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p class="paragraph lh-30">
                在{{ data.summary.start_time }}至{{ data.summary.end_time }}期间的{{ data.summary.total_num }}条信息中，
                {{ data.opinion_weibo_analysis.total }}条来微博、其中包含{{ data.opinion_weibo_analysis.original }}条原发微博。
                {{ data.opinion_weibo_analysis.forward }}条转发微博。观点主要来自于{{ data.opinion_weibo_analysis.usertype }}，
                包含{{ data.opinion_weibo_analysis.usertype_count }}条微博。以下将罗列各用户类别的重要观点。
              </p>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-row :gutter="20" class="el-row-20">
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">微博类型观点分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty hide">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_weibo_forward"></div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <h3 class="mod-title">微博用户观点分布</h3>
                <div class="pad-lr-20">
                  <div class="echarts_empty" v-show="data.opinion_weibo_usertype_list.type.length < 1">
                    <div class="img"></div>
                  </div>
                  <div class="h-250" id="opinion_weibo_usertype_list" v-show="data.opinion_weibo_usertype_list.type.length >= 1"></div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博原文观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_source_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_source_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_source_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博转发观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_forward_manner'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_forward_manner'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_forward_manner']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">金V观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v1'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v1'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v1']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">橙V观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v2'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v2'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v2']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">蓝V观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v3'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v3'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v3']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">达人观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v4'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v4'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v4']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">微博女郎</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v5'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v5'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v5']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card">
            <h3 class="mod-title">普通用户</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_usertype_v6'].length < 1">
                <div class="img"></div>
              </div>
              <div class="mod-list picture_list" v-show="data['opinion_weibo_usertype_v6'].length >= 1">
                <ul>
                  <li>
                    <h3><span class="num-tit">序号</span><span class="mod-list-title2">观点</span><span class="xgwbs">相关微博数</span><span class="xgwbs">微博占比</span><span class="redu dbyh">代表用户</span></h3>
                  </li>
                  <li v-for="(row, index) in data['opinion_weibo_usertype_v6']" :key="index">
                    <span class="num bg-555" v-if="index == 0">1</span>
                    <span class="num bg-689" v-else-if="index == 1">2</span>
                    <span class="num bg-8ea" v-else-if="index == 2">3</span>
                    <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                    <a class="mod-list-title2" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                    <span class="xgwbs">{{ row.count }}条</span>
                    <span class="xgwbs">{{ row.percent }}%</span>
                    <dl>
                      <dd v-for="(user, index) in row.users" :key="index">
                        <a class="img">
                          <img :src="user.avatar" width="40" />
                          <span class="ico v_red" v-if="user.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="user.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="user.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="user.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="user.type == '达人'"></span>
                        </a>
                        <a :href="user.home" target="_blank" class="name" :title="user.name">{{ user.name }}</a>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </div>
        <div class="warpper pt-20">
          <el-card class="box-card txt_list">
            <h3 class="mod-title">意见领袖观点</h3>
            <div class="pad-lr-20">
              <div class="echarts_empty" v-show="data['opinion_weibo_leader'].length < 1">
                <div class="img"></div>
              </div>
              <div class="w-100" v-show="data['opinion_weibo_leader'].length >= 1">
                <el-row :gutter="20" class="pt-20 el-row-20">
                  <el-col :span="8" v-for="(row, index) in data['opinion_weibo_leader']" :key="index" v-if="index <= 2">
                    <div class="mtgd_je">
                      <div class="flex">
                        <a :href="row.home" target="_blank" class="img">
                          <img :src="row.avatar" width="60" />
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </a>
                        <div class="mar-t-10">
                          <h5><a :href="row.home" target="_blank">{{ row.name }}</a></h5>
                          <p>相关微博数：<span>{{ row.count }}条</span></p>
                        </div>
                      </div>
                      <ul>
                        <li v-for="(article, index) in row.article"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_weibo_leader'].length > 3">
                  <el-col :span="8" v-for="(row, index) in data['opinion_weibo_leader']" :key="index" v-if="index > 2 && index <= 5">
                    <div class="mtgd_je">
                      <div class="flex">
                        <a :href="row.home" target="_blank" class="img">
                          <img :src="row.avatar" width="60" />
                          <span class="ico v_red" v-if="row.type == '金V'"></span>
                          <span class="ico v_orange" v-else-if="row.type == '黄V'"></span>
                          <span class="ico v_blue" v-else-if="row.type == '蓝V'"></span>
                          <span class="ico v_pink" v-else-if="row.type == '微博女郎'"></span>
                          <span class="ico v_talent_show" v-else-if="row.type == '达人'"></span>
                        </a>
                        <div class="mar-t-10">
                          <h5><a :href="row.home" target="_blank">{{ row.name }}</a></h5>
                          <p>相关微博数：<span>{{ row.count }}条</span></p>
                        </div>
                      </div>
                      <ul>
                        <li v-for="(article, index) in row.article"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                      </ul>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
        <!-- 微信观点分析 -->
        <div class="hide">
          <a name="wxgd" id="wxgd"></a>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">微信观点分析</h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph lh-30">在{{ data.summary.start_time }}至{{ data.summary.end_time }}期间的{{ data.summary.total_num }}条信息中，{{ data.opinion_weixin_analysis.total }}条来自微信公众号。以下将罗列重要观点。</p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card gnmtgd">
              <h3 class="mod-title">微信公众号观点</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['opinion_weixin_media'].length < 1">
                  <div class="img"></div>
                </div>
                <div class="mod-list" v-show="data['opinion_weixin_media'].length >= 1">
                  <ul>
                    <li>
                      <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">相关文章数</span><span class="tag">信息占比</span><span class="source">代表公众号</span></h3>
                    </li>
                    <li v-for="(row, index) in data['opinion_weixin_media']" :key="index">
                      <span class="num bg-555" v-if="index == 0">1</span>
                      <span class="num bg-689" v-else-if="index == 1">2</span>
                      <span class="num bg-8ea" v-else-if="index == 2">3</span>
                      <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
                      <a class="mod-list-title1" :href="row.source_url" target="_blank" :title="row.opinion">{{ row.opinion }}</a>
                      <span class="tag">{{ row.count }}条</span>
                      <span class="tag">{{ row.percent }}%</span>
                      <p class="source">
                        <a v-for="(media, index) in row.media" :key="index" :title="media"><var>{{ media }}</var> <span>|</span></a>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card txt_list">
              <h3 class="mod-title">主要公众号观点</h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['opinion_weixin_main_media'].length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100" v-show="data['opinion_weixin_main_media'].length >= 1">
                  <el-row :gutter="20" class="pt-20 el-row-20">
                    <el-col :span="8" v-for="(row, index) in data['opinion_weixin_main_media']" :key="index" v-if="index <= 2">
                      <div class="mtgd_je">
                        <h5><a>{{ row.media }}</a></h5>
                        <p>相关文章数：<span>{{ row.count }}条</span></p>
                        <ul>
                          <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="pt-20 el-row-20" v-if="data['opinion_weixin_main_media'].length > 3">
                    <el-col :span="8" v-for="(row, index) in data['opinion_weixin_main_media']" :key="index" v-if="index > 2 && index <= 5">
                      <div class="mtgd_je">
                        <h5><a>{{ row.media }}</a></h5>
                        <p>相关文章数：<span>{{ row.count }}条</span></p>
                        <ul>
                          <li v-for="(article, index) in row.article" :key="index"><a :href="article.source_url" target="_blank" :title="article.title">{{ article.title }}</a></li>
                        </ul>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
import {
  findPointImg,
  globalCreateChart,
  globalGetEcharts,
  globalMediaColor
} from '@/utils/helpers.js'
export default {
  name: 'analysis-view',
  data() {
    return {
      title: '',
      data: {
        summary: {
          domestic_num: 11748,
          domestic_manner: '多为负面',
          oversea_num: 103,
          oversea_manner: '多为负面',
          total_num: 92125,
          start_time: '2019-07-01 00:00:00',
          end_time: '2019-08-05 23:59:59'
        },
        state_sum: {
          weibo:6690486,
          pinglun:240308,
          zimeitihao:152333,
          wangmei:72694,
          kehuduan:38137,
          luntan:18017,
          shipin:9887,
          zhengwu:4698,
        },
        domestic_dis: {
          negative: 6874,
          positive: 4874
        },
        oversea_dis: {
          negative: 66,
          positive: 37
        },
        domestic_media_analyse: [
          {
            opinion: '警员手指被暴徒生生咬断！香港以法治乱势在必行',
            rowkey: '10105839365840cef3dafce72377d2',
            source_url: 'http://baijiahao.baidu.com/s?id=1639173365394361007&wfr=spider',
            count: 5407,
            percent: 46.02,
            media: ['北京军都医', '优久商务网', '四方资源网']
          },
          {
            opinion: '香港警员被示威者围殴咬断手指 港警：行为犹如野兽',
            rowkey: '10105842353880d6f446cae17437c8',
            source_url: 'http://baijiahao.baidu.com/s?id=1639178257954750205&wfr=spider&for=pc',
            count: 429,
            percent: 3.65,
            media: ['活动网', '中评网', '红歌会网']
          },
          {
            opinion: '外国势力策划香港暴力事件 中国正告美英收回在港黑手',
            rowkey: '10105835686470e72c990b0a04926f',
            source_url: 'http://baijiahao.baidu.com/s?id=1639893698413613386&wfr=spider',
            count: 238,
            percent: 2.03,
            media: ['活动网', '北京军都医', '海军360']
          },
          {
            opinion: '香港法院启动审理45人涉嫌暴动、袭警案',
            rowkey: '10105829498670a0626bf548109564',
            source_url: 'http://news.haiwainet.cn/n/2019/0731/c3541083-31602650.html',
            count: 214,
            percent: 1.82,
            media: ['新华社', '上海冰峰仪表塑料件厂', '红歌会网']
          },
          {
            opinion: '气愤!港教师毒咒警察子女“活不过7岁”,梁振英:教育界之耻',
            rowkey: '101058304070704aa3923bbb73e6d1',
            source_url: 'https://baijiahao.baidu.com/s?id=1640437407708034061&wfr=spider',
            count: 177,
            percent: 1.51,
            media: ['鹏洋网络', '星岛环球网', '搜狐']
          },
          {
            opinion: '香港激进分子冲击警署还扬言要劫走嫌犯 警方严正警告',
            rowkey: '10105826529270adf55c9f45dcaff9',
            source_url: 'http://www.sohu.com/a/331299593_115376?g=0',
            count: 169,
            percent: 1.44,
            media: ['鹏洋网络', '海泡新闻网', '中华网河北']
          },
          {
            opinion: '香港“末代港督”彭定康大肆吹捧“港独” 港媒斥责：太无耻',
            rowkey: '1010584925307088639170ec447330',
            source_url: 'http://baijiahao.baidu.com/s?id=1605246647320579896&wfr=spider&for=pc',
            count: 159,
            percent: 1.35,
            media: ['网易', '大众网', '星岛环球网']
          },
          {
            opinion:
              '黑猫投诉：我在携程网上预定了香港7.30到8.1的旺角酒店两晚 但现在香港暴动要求退款|携程|旺角|携程',
            rowkey: '101058369684109f1c990cd758857d',
            source_url: 'http://news.sina.com.cn/ts/2019-07-22/doc-ihytcitm3814568.shtml',
            count: 126,
            percent: 1.07,
            media: ['北京军都医', '上海冰峰仪表塑料件厂', '宇速科技网']
          },
          {
            opinion: '6月香港的15岁们：像打游戏一样',
            rowkey: '101058346868706139bbcd1a16fb36',
            source_url: 'http://www.ywqi.cn/2019/2515.html',
            count: 124,
            percent: 1.06,
            media: ['四川自考信息网', '山西自考信息网', '威宁彝族回族苗族自治县教育局']
          },
          {
            opinion: '人民日报：香港 决不容忍这样闹下去',
            rowkey: '101058272204709422b48cfcad8d0d',
            source_url: 'https://news.sina.com.cn/c/2019-08-03/doc-ihytcerm8327011.shtml',
            count: 112,
            percent: 0.95,
            media: ['中国经济网', '大众网', '红山网']
          }
        ],
        opinion_domestic_media_chronological: {
          data: {
            trend: [
              {
                count: 1110,
                date: '2019-07-01',
                points: ['开始点']
              },
              {
                count: 1692,
                date: '2019-07-02',
                points: []
              },
              {
                count: 2398,
                date: '2019-07-03',
                points: []
              },
              {
                count: 2046,
                date: '2019-07-04',
                points: []
              },
              {
                count: 1827,
                date: '2019-07-05',
                points: []
              },
              {
                count: 1127,
                date: '2019-07-06',
                points: []
              },
              {
                count: 1615,
                date: '2019-07-07',
                points: []
              },
              {
                count: 1383,
                date: '2019-07-08',
                points: []
              },
              {
                count: 2606,
                date: '2019-07-09',
                points: []
              },
              {
                count: 1271,
                date: '2019-07-10',
                points: []
              },
              {
                count: 1013,
                date: '2019-07-11',
                points: []
              },
              {
                count: 967,
                date: '2019-07-12',
                points: []
              },
              {
                count: 1110,
                date: '2019-07-01',
                points: []
              },
              {
                count: 811,
                date: '2019-07-13',
                points: []
              },
              {
                count: 935,
                date: '2019-07-14',
                points: []
              },
              {
                count: 4796,
                date: '2019-07-15',
                points: []
              },
              {
                count: 3862,
                date: '2019-07-16',
                points: []
              },
              {
                count: 2178,
                date: '2019-07-17',
                points: []
              },
              {
                count: 2643,
                date: '2019-07-18',
                points: []
              },
              {
                count: 1419,
                date: '2019-07-19',
                points: []
              },
              {
                count: 1005,
                date: '2019-07-20',
                points: ['最低点']
              },
              {
                count: 1775,
                date: '2019-07-21',
                points: ['爆发点']
              },
              {
                count: 6813,
                date: '2019-07-22',
                points: ['高峰点']
              },
              {
                count: 5328,
                date: '2019-07-23',
                points: ['分界点']
              },
              {
                count: 6419,
                date: '2019-07-24',
                points: ['高峰点']
              },
              {
                count: 2956,
                date: '2019-07-25',
                points: ['分界点']
              },
              {
                count: 6227,
                date: '2019-07-26',
                points: []
              },
              {
                count: 8298,
                date: '2019-07-27',
                points: []
              },
              {
                count: 7690,
                date: '2019-07-28',
                points: []
              },
              {
                count: 10761,
                date: '2019-07-29',
                points: []
              },
              {
                count: 8133,
                date: '2019-07-30',
                points: ['爆发点']
              },
              {
                count: 12219,
                date: '2019-07-31',
                points: []
              },
              {
                count: 12652,
                date: '2019-08-01',
                points: []
              },
              {
                count: 18443,
                date: '2019-08-02',
                points: []
              },
              {
                count: 14879,
                date: '2019-08-03',
                points: []
              },
              {
                count: 16229,
                date: '2019-08-04',
                points: []
              },
              {
                count: 25225,
                date: '2019-08-05',
                points: ['高峰点', '结束点']
              }
            ],
            timeline: [
              {
                article: {
                  medias: ['新浪', '环球网', '搜狐'],
                  source_url: 'http://mil.news.sina.com.cn/2019-07-03/doc-ihytcerm0649499.shtml',
                  title: '香港前警界“一哥”接受专访：现在已经不是“修例不修例”的问题了',
                  percent: 44.74,
                  total: 136
                },
                date: '2019-07-01',
                points: ['开始点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '新浪',
                    source_url: 'http://mil.news.sina.com.cn/2019-07-03/doc-ihytcerm0649499.shtml',
                    title: '香港前警界“一哥”接受专访：现在已经不是“修例不修例”的问题了 '
                  },
                  {
                    media: '中华新闻网',
                    source_url: 'https://news.china.com/domestic/945/20190704/36535319.html',
                    title: '中国驻英大使：坚决反对英方粗暴干涉香港事务和中国内政 '
                  },
                  {
                    media: '环球网',
                    source_url: 'http://china.huanqiu.com/gangao/2019-07/15148189.html?agt=15438',
                    title: '港警：咬断警员手指行为犹如野兽！'
                  }
                ],
                state: '潜伏期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['环球网', '百家号', '中华网'],
                  source_url: 'http://china.huanqiu.com/gangao/2019-07/15148189.html?agt=15438',
                  title: '港警：咬断警员手指行为犹如野兽！',
                  percent: 48.55,
                  total: 134
                },
                date: '2019-07-20',
                points: ['最低点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['环球网', '新浪', '百家号'],
                  source_url:
                    'https://m.huanqiu.com/r/MV8wXzExMjU2Mzg4XzEyNjRfMTUwNTY3ODQwMA==?pc_url=http%3A%2F%2Fchina.huanqiu.com%2Farticle%2F2017-09%2F11256388.html',
                  title: '不要祸害下一代！ 香港4000人呐喊要赶走“港独”祸首',
                  percent: 28.27,
                  total: 132
                },
                date: '2019-07-21',
                points: ['爆发点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '环球网',
                    source_url:
                      'https://m.huanqiu.com/r/MV8wXzExMjU2Mzg4XzEyNjRfMTUwNTY3ODQwMA==?pc_url=http%3A%2F%2Fchina.huanqiu.com%2Farticle%2F2017-09%2F11256388.html',
                    title: '不要祸害下一代！ 香港4000人呐喊要赶走“港独”祸首'
                  },
                  {
                    media: '中华网',
                    source_url: 'https://news.china.com/domestic/945/20190719/36646309.html',
                    title: '外交部回应蔡英文涉港言论 某些人自己不过是个"泥菩萨"'
                  },
                  {
                    media: '环球网',
                    source_url:
                      'http://opinion.huanqiu.com/editorial/2019-07/15177942.html?agt=15438',
                    title: '社评：公然挑战中央权威决不会有好下场'
                  }
                ],
                state: '爆发期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['环球网', '观察者网', '百家号'],
                  source_url:
                    'http://opinion.huanqiu.com/editorial/2019-07/15177942.html?agt=15438',
                  title: '社评：公然挑战中央权威决不会有好下场',
                  percent: 57.07,
                  total: 335
                },
                date: '2019-07-22',
                points: ['高峰点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['百家号', '新华网', '四川日报'],
                  source_url: 'https://baijiahao.baidu.com/s?id=1639810577591858614&wfr=spider',
                  title: '中联办：辱国恶行公然挑战中央权威，将坚定履责！香港各界强烈谴责乱港行为',
                  percent: 39.3,
                  total: 147
                },
                date: '2019-07-23',
                points: ['分界点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['环球网', '新浪', '中华网'],
                  source_url: 'https://3w.huanqiu.com/a/de583b/7OymZRzPqP6?agt=46',
                  title: '社评：大赦暴徒？香港反对派别做梦了',
                  percent: 40.39,
                  total: 393
                },
                date: '2019-07-24',
                points: ['高峰点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['中国新闻网', '新浪', '中新网'],
                  source_url: 'http://www.chinanews.com/ga/2019/07-25/8907007.shtml',
                  title: '香港多个公务员团体呼吁公务员队伍支持特区政府依法施政和警队依法执法',
                  percent: 66.8,
                  total: 161
                },
                date: '2019-07-25',
                points: ['分界点'],
                type: 'point'
              },
              {
                article: {
                  medias: ['新浪', '环球网', '网易'],
                  source_url:
                    'http://mil.news.sina.com.cn/dgby/2019-07-30/doc-ihytcitm5589924.shtml',
                  title: '香港哗然！有教师竟如此恶毒诅咒警察子女',
                  percent: 55.01,
                  total: 401
                },
                date: '2019-07-30',
                points: ['爆发点'],
                type: 'point'
              },
              {
                articles: [
                  {
                    media: '新浪',
                    source_url:
                      'http://mil.news.sina.com.cn/dgby/2019-07-30/doc-ihytcitm5589924.shtml',
                    title: '香港哗然！有教师竟如此恶毒诅咒警察子女'
                  },
                  {
                    media: '人民网',
                    source_url: 'http://world.people.com.cn/n1/2019/0802/c1002-31273787.html',
                    title: '外交部：要求美方恪守承诺不插手香港事务'
                  },
                  {
                    media: '中国新闻网',
                    source_url: 'http://www.chinanews.com/gn/2019/08-05/8917803.shtml',
                    title: '华春莹：任何人都不要低估中方维护香港繁荣稳定的坚定决心'
                  }
                ],
                state: '爆发期',
                type: 'interval'
              },
              {
                article: {
                  medias: ['中国新闻网', '百家号', '大众网'],
                  source_url: 'http://www.chinanews.com/gn/2019/08-05/8917803.shtml',
                  title: '华春莹：任何人都不要低估中方维护香港繁荣稳定的坚定决心',
                  percent: 68.63,
                  total: 361
                },
                date: '2019-08-05',
                points: ['高峰点', '结束点'],
                type: 'point'
              }
            ]
          },
          isIncubation: true
        },
        opinion_domestic_media_neg: [
          {
            opinion: '警员手指被暴徒生生咬断！香港以法治乱势在必行',
            rowkey: '10105839365840cef3dafce72377d2',
            source_url: 'http://baijiahao.baidu.com/s?id=1639173365394361007&wfr=spider',
            count: 3039,
            percent: 44.2,
            media: ['优久商务网', '四方资源网', '北京军都医']
          },
          {
            opinion: '民进党打“香港牌”上了瘾,就不考虑临界点在哪吗?',
            rowkey: '10105831147660fc2b473d7b6acffc',
            source_url: 'https://baijiahao.baidu.com/s?id=1640359662538538864&wfr=spider',
            count: 239,
            percent: 3.48,
            media: ['中评网', '红歌会网', '中华网河北']
          },
          {
            opinion: '香港激进分子冲击警署还扬言要劫走嫌犯 警方严正警告',
            rowkey: '10105826529270adf55c9f45dcaff9',
            source_url: 'http://www.sohu.com/a/331299593_115376?g=0',
            count: 165,
            percent: 2.4,
            media: ['鹏洋网络', '海泡新闻网', '潮州人新闻网']
          },
          {
            opinion: '气愤!港教师毒咒警察子女“活不过7岁”,梁振英:教育界之耻',
            rowkey: '101058304070704aa3923bbb73e6d1',
            source_url: 'https://baijiahao.baidu.com/s?id=1640437407708034061&wfr=spider',
            count: 155,
            percent: 2.25,
            media: ['鹏洋网络', '网易', '搜狐']
          },
          {
            opinion: '香港的暴徒和自家政客的口水，英国人看不下去了',
            rowkey: '10105850219670b07bd1f67266b9b6',
            source_url: 'http://www.sohu.com/a/325295171_115479?scm=1002.3d003a.f30174.0-0',
            count: 144,
            percent: 2.09,
            media: ['大众网', '网易', '星岛环球网']
          },
          {
            opinion: '6月香港的15岁们：像打游戏一样',
            rowkey: '101058346868706139bbcd1a16fb36',
            source_url: 'http://www.ywqi.cn/2019/2515.html',
            count: 124,
            percent: 1.8,
            media: ['四川自考信息网', '山西自考信息网', '惠众科技网']
          },
          {
            opinion: '香港激进示威者父母痛心落泪:孩子被人利用了',
            rowkey: '10105829256450c893a3bc24b9f5a1',
            source_url: 'http://news.sina.com.cn/c/2019-07-31/doc-ihytcerm7579011.shtml',
            count: 119,
            percent: 1.73,
            media: ['活动网', '中国科学院学部网站', '人民政协网']
          },
          {
            opinion: '香港警方拘捕49名暴力示威者 45人将被控罪',
            rowkey: '10105829951070aa47acc55af56da3',
            source_url: 'http://www.sohu.com/a/330374272_114988?tc_tab=news&block=news&index=4',
            count: 95,
            percent: 1.38,
            media: ['大众网', '中国经济网', '央广网']
          },
          {
            opinion: '又玩“双标”！香港暴力示威者要是在英美，早就吃上牢饭了！',
            rowkey: '10105832404470d3a399a87cdb3943',
            source_url: 'http://china.huanqiu.com/gangao/2019-07/15211127.html',
            count: 83,
            percent: 1.21,
            media: ['湘潭市路桥科技培训学校', '中评网', '中国经济网']
          },
          {
            opinion: '屡屡铐上香港极端示威者的“速龙小队” 什么来头',
            rowkey: '10105829812470bef633ef2c29ad5c',
            source_url: 'https://news.sina.com.cn/c/2019-07-31/doc-ihytcerm7470506.shtml',
            count: 76,
            percent: 1.11,
            media: ['中国经济网', '红山网', '东方网']
          }
        ],
        opinion_domestic_main_media: [
          {
            media: '新浪网',
            count: 189,
            article: [
              {
                title: '环球时报：暴徒攻击香港立法会 行径令人发指|暴力',
                rowkey: '1010585484987066cb73c93dc5c68e',
                source_url: 'https://news.sina.com.cn/c/2019-07-02/doc-ihytcerm0657500.shtml'
              },
              {
                title: '陈百祥为警察打气:和所有正常的市民继续支持你们|陈百祥',
                rowkey: '101058464366700f89768c89aa0db2',
                source_url: 'https://news.sina.com.cn/c/gat/2019-07-11/doc-ihytcerm2972566.shtml'
              },
              {
                title: '突破底线 暴徒袭击香港警察情节升级|观察者网|沙田',
                rowkey: '1010584283427044de9207bfb43b18',
                source_url:
                  'http://news.sina.com.cn/c/2019-07-15/doc-ihytcitm2223810.shtml?cre=tianyi&mod=pchot_gongyi&loc=8&r=0&rfunc=100&tj=none&tr=1'
              }
            ]
          },
          {
            media: '搜狐',
            count: 165,
            article: [
              {
                title: '香港的暴徒和自家政客的口水，英国人看不下去了',
                rowkey: '10105850219670b07bd1f67266b9b6',
                source_url: 'http://www.sohu.com/a/325295171_115479?scm=1002.3d003a.f30174.0-0'
              },
              {
                title: '香港暴徒袭警、火攻、扔腐蚀性物质，警方再拘44人',
                rowkey: '10105849045470d5c6c00f7ecde4d8',
                source_url:
                  'http://www.sohu.com/a/331512278_115376?spm=smpc.home.top-news2.1.1564990666962ROyIX8N&_f=index_news_0'
              },
              {
                title: '林郑月娥警告：小心“玉石俱焚”将香港推上不归路！',
                rowkey: '101058421484704169b003a27fc984',
                source_url:
                  'http://www.sohu.com/a/331563667_162522?spm=smpc.home.top-news2.2.1564990666962ROyIX8N'
              }
            ]
          },
          {
            media: 'ZAKER',
            count: 145,
            article: [
              {
                title: '社评：对暴徒冲击香港立法会的行径大声说不 ',
                rowkey: '1010585484613039015d4feea7cc5b',
                source_url: 'http://www.myzaker.com/article/5d1a2e398e9f096c04463041/'
              },
              {
                title: '环球时报：对暴徒冲击香港立法会的行径大声说不 ',
                rowkey: '10105854600150ffc4807a285e3232',
                source_url: 'http://www.myzaker.com/article/5d1a940832ce40c0fa000007/'
              },
              {
                title: '香港反对派阻断火车瘫痪交通，市民怒骂：暴徒！',
                rowkey: '10105854024140613150a11b9fabaf',
                source_url: 'http://www.myzaker.com/article/5d47c88a32ce403d9c000036/'
              }
            ]
          },
          {
            media: '大众网',
            count: 127,
            article: [
              {
                title: '暴力祸港者必须严惩',
                rowkey: '101058537570506a74d3f65453f54d',
                source_url: 'http://www.dzwww.com/xinwen/shehuixinwen/201907/t20190703_18898124.htm'
              },
              {
                title: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
                rowkey: '101058373063408f7429ecbbbb31d9',
                source_url: 'http://www.dzwww.com/xinwen/shehuixinwen/201907/t20190722_18969966.htm'
              },
              {
                title: '香港法律界人士对法治基础遭破坏感到痛心',
                rowkey: '101058311624703583bc8f449ec152',
                source_url: 'http://news.dzwww.com/guojixinwen/201907/t20190729_18996453.htm'
              }
            ]
          },
          {
            media: '凤凰网',
            count: 110,
            article: [
              {
                title: '环球社评：暴徒在香港立法会的行径令人发指 必须强烈谴责',
                rowkey: '1010585457774082204bcbcc444975',
                source_url: 'https://news.ifeng.com/c/7nyPWCidjNo'
              },
              {
                title: '追思丨智慧长老2016年新春团拜 ：切记恶业得恶报',
                rowkey: '101058440621200d0cf20c0fee8955',
                source_url: 'https://fo.ifeng.com/a/20190714/45512793_0.shtml'
              },
              {
                title: '胡锡进：香港乱了，有人希望北京出手，这可行吗？',
                rowkey: '101058353215105a8f50c8d87b9dae',
                source_url: 'http://news.ifeng.com/c/7oZR6ISLayG'
              }
            ]
          },
          {
            media: '网易',
            count: 89,
            article: [
              {
                title: '维修费超5000万元！香港警方将拘捕冲击立法会暴徒',
                rowkey: '10105853229860051ad2bacc65c19d',
                source_url: 'https://news.163.com/19/0703/21/EJ6KSDQD0001899N.html'
              },
              {
                title: '香港政团社团谴责示威者围堵香港中联办:严惩暴力',
                rowkey: '10105837458730982bde55dfe76879',
                source_url: 'https://news.163.com/19/0722/03/EKLKU34L0001875N.html'
              },
              {
                title: '环球记者现场直击：暴徒围堵中联办绝非临时起意',
                rowkey: '10105836389820216e9ba08aef2a3f',
                source_url: 'http://news.163.com/19/0723/09/EKOQS56U0001875N.html'
              }
            ]
          }
        ],
        opinion_oversea: [
          {
            opinion: '上环冲突44被告获准保释 但须守宵禁令',
            rowkey: '1010582933007054c360bad4ee3a78',
            source_url: 'http://www.zaobao.com/realtime/china/story20190731-977087',
            count: 17,
            percent: 16.5,
            media: ['联合早报', '侨报网']
          },
          {
            opinion: 'Mall clashes at latest Hong Kong anti-extradition march',
            rowkey: '10105830550470249f63097f7c3967',
            source_url: 'https://today.rtl.lu/news/world/a/1376444.html',
            count: 6,
            percent: 5.83,
            media: ['RTL', 'Business Times', 'Gulf News']
          },
          {
            opinion: 'Hong Kong Charges Dozens Of Protesters With Rioting',
            rowkey: '101058296864702619af1cbe2f8de4',
            source_url:
              'https://www.npr.org/2019/07/31/746940875/hong-kong-charges-dozens-of-protesters-with-rioting',
            count: 5,
            percent: 4.85,
            media: ['NPR', 'Nytimes', 'France24']
          },
          {
            opinion:
              "Trump calls Hong Kong unrest “riots” and suggests it's an issue between HK and Beijing",
            rowkey: '10105827958450bd49f3d12175044f',
            source_url:
              'https://en.mercopress.com/2019/08/03/trump-calls-hong-kong-unrest-riots-and-suggests-it-s-an-issue-between-hk-and-beijing',
            count: 5,
            percent: 4.85,
            media: ['Merco Press', 'SCMP', 'Bloom Berg']
          },
          {
            opinion: "Beijing calls Hong Kong protests 'riots', supports govt's response",
            rowkey: '10105829909070e7a9227dc35268dc',
            source_url:
              'https://www.msn.com/en-us/news/world/beijing-calls-hong-kong-protests-riots-supports-govts-response/ar-AACP6Bc',
            count: 4,
            percent: 3.88,
            media: ['MSN', 'The Guardian', 'Washington Post']
          },
          {
            opinion: 'Hong Kong Protesters Clash With Police at March Against Mob Violence',
            rowkey: '10105827395620471ac6f96d3a20f7',
            source_url: 'https://time.com/5636825/hong-kong-yuen-long-protest/',
            count: 4,
            percent: 3.88,
            media: ['Time', 'SCMP', 'Yahoo']
          },
          {
            opinion:
              'Hong Kong protesters vow to stand up to unprecedented riot charges as movement faces new chapter',
            rowkey: '101058491812809ce73cb294504aff',
            source_url:
              'https://www.independent.co.uk/news/world/asia/hong-kong-protest-riot-charges-china-a9037351.html',
            count: 4,
            percent: 3.88,
            media: ['Independent', 'Wall Street Journal']
          },
          {
            opinion: 'Lily Kuo: China bristling over Hong Kong unrest',
            rowkey: '10105826230450d2f971484c18cb76',
            source_url:
              'https://www.rnz.co.nz/national/programmes/sunday/audio/2018707118/lily-kuo-china-bristling-over-hong-kong-unrest',
            count: 4,
            percent: 3.88,
            media: ['RNZ', 'Player FM']
          },
          {
            opinion:
              'Hong Kong police call protesters ‘illegal, irrational and unreasonable’ over siege of headquarters',
            rowkey: '10105830403120b1d2a21ab2ce4d47',
            source_url:
              'https://www.cnbc.com/2019/06/22/hong-kong-unrest-police-condemn-protests-as-illegal-after-hq-siege.html',
            count: 4,
            percent: 3.88,
            media: ['CNBC', 'MSN', 'Yahoo']
          },
          {
            opinion: 'Hong Kong Braces for More Unrest After Saturday’s Clashes',
            rowkey: '1010582795845034ff602521f2ed36',
            source_url: 'https://news.yahoo.com/hong-kong-police-warn-arrests-072623457.html',
            count: 4,
            percent: 3.88,
            media: ['Yahoo', 'Bloom Berg']
          }
        ],
        opinion_oversea_main_media: [
          {
            media: '联合早报',
            count: 26,
            article: [
              {
                title: '上环冲突44被告获准保释 但须守宵禁令',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.zaobao.com/realtime/china/story20190731-977087'
              },
              {
                title: '港澳办挺林郑与港警 分析：显示北京视为治安问题',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.zaobao.com/znews/greater-china/story20190730-976618'
              },
              {
                title: '港示威者葵涌警署外抗议',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.zaobao.com/znews/greater-china/story20190731-976922'
              }
            ]
          },
          {
            media: '侨报网',
            count: 20,
            article: [
              {
                title: '特朗普定性香港游行是“暴乱” 华春莹：这回总统是对的！',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.uschinapress.com/2019/0802/1170204.shtml'
              },
              {
                title: '暴徒拆下五星红旗扔海里 香港市民凌晨重新升国旗',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.uschinapress.com/2019/0804/1170219.shtml'
              },
              {
                title: '内地严厉谴责香港暴徒侮辱国旗 中国网民争当护旗手',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'http://www.uschinapress.com/2019/0804/1170228.shtml'
              }
            ]
          },
          {
            media: 'MSN',
            count: 12,
            article: [
              {
                title: "Beijing calls Hong Kong protests 'riots', supports govt's response",
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.msn.com/en-us/news/world/beijing-calls-hong-kong-protests-riots-supports-govts-response/ar-AACP6Bc'
              },
              {
                title: 'Hong Kong police charge 44 protesters with rioting',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'https://msn.al/hong-kong-police-charge-44-protesters-with-rioting/'
              },
              {
                title: 'In photos: Hong Kong protests',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.msn.com/en-us/news/world/in-photos-hong-kong-protests/ss-AACEMhP'
              }
            ]
          },
          {
            media: 'NPR',
            count: 8,
            article: [
              {
                title: 'Hong Kong Police Fire Tear Gas At Protesters As Season Of Unrest Continues',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url: 'https://news.yahoo.com/hong-kong-police-warn-arrests-072623457.html'
              },
              {
                title: 'Hong Kong Charges Dozens Of Protesters With Rioting',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.npr.org/2019/07/31/746940875/hong-kong-charges-dozens-of-protesters-with-rioting'
              },
              {
                title:
                  'Police In Hong Kong Fire Tear Gas As Demonstrators Rally In Response To Attack',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.npr.org/2019/07/27/745847341/police-in-hong-kong-fire-tear-gas-as-demonstrators-rally-in-response-to-attack'
              }
            ]
          },
          {
            media: 'France24',
            count: 6,
            article: [
              {
                title: 'Hong Kong police fire teargas in second night of clashes',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.france24.com/en/20190804-hong-kong-police-teargas-violent-clashes-protesters'
              },
              {
                title:
                  "Strike grips Hong Kong as embattled leader warns of challenge to China's sovereignty",
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.france24.com/en/20190805-hong-kong-protesters-general-strike-extradition-bill-communist-china'
              },
              {
                title: 'Hong Kong protesters clash with police, throw Chinese flag into harbor',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.france24.com/en/20190803-hong-kong-protesters-defy-china-warnings-extradition-weekend-rallies'
              }
            ]
          },
          {
            media: 'RNZ',
            count: 4,
            article: [
              {
                title: 'Hundreds of Hong Kong flights canned as protests grip city',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.rnz.co.nz/news/world/396009/hundreds-of-hong-kong-flights-canned-as-protests-grip-city'
              },
              {
                title: 'Police fire tear gas at Hong Kong protesters',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.rnz.co.nz/news/world/395931/police-fire-tear-gas-at-hong-kong-protesters'
              },
              {
                title: 'Lily Kuo: China bristling over Hong Kong unrest',
                rowkey: '10105837247730e69efcda4f15df62',
                source_url:
                  'https://www.rnz.co.nz/national/programmes/sunday/audio/2018707118/lily-kuo-china-bristling-over-hong-kong-unrest'
              }
            ]
          }
        ],
        opinion_gov_analysis: {
          gov_count: 893,
          gov_manner: '多为负面',
          word: '香港 && (暴乱 || 社会骚乱 || 暴动)'
        },
        opinion_gov_dis: {
          negative: 638,
          positive: 255
        },
        opinion_gov_main: {
          type: ['中国经济网', '大众网', '人民网', '央广网', '海外网', '中国新闻网'],
          data: [141, 127, 76, 56, 49, 45]
        },
        opinion_gov_manner: [
          {
            opinion: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
            rowkey: '10105837334520ff2be150679fd89c',
            source_url: 'http://hm.people.com.cn/n1/2019/0722/c42272-31246853.html#liuyan',
            count: 254,
            percent: 28.44,
            media: ['大众网', '中国经济网', '海外网']
          },
          {
            opinion: '暴力祸港者必须严惩',
            rowkey: '10105853674390ea204b06a8a7b4da',
            source_url: 'http://hm.people.com.cn/n1/2019/0703/c42272-31209738.html#liuyan',
            count: 73,
            percent: 8.17,
            media: ['国际在线', '央广网', '中国经济网']
          },
          {
            opinion: '香港社会急需恢复理性安宁',
            rowkey: '10105831540470ef4da38b965f6ea8',
            source_url: 'http://hm.people.com.cn/n1/2019/0729/c42272-31260215.html#liuyan',
            count: 42,
            percent: 4.7,
            media: ['大众网', '中国经济网', '央广网']
          },
          {
            opinion: '新华时评：外部势力抹黑“一国两制”意在乱港反华',
            rowkey: '101058332684701fe55ed7a1cfb77f',
            source_url: 'http://gs.people.com.cn/n2/2019/0727/c183342-33186185.html',
            count: 29,
            percent: 3.25,
            media: ['中国经济网', '大众网', '人民网']
          },
          {
            opinion: '香港示威者冲击中联办 这是我们在现场看到的真相',
            rowkey: '10105836724470e6fbf5c6397d00c6',
            source_url: 'http://m.ce.cn/sh/sgg/201907/23/t20190723_32694668.shtml',
            count: 28,
            percent: 3.14,
            media: ['中国经济网', '大众网', '央广网']
          },
          {
            opinion: '香港法律界人士对法治基础遭破坏感到痛心',
            rowkey: '10105831466780bf5a886a82d5e7e4',
            source_url: 'http://www.xinhuanet.com/2019-07/29/c_1124808687.htm',
            count: 22,
            percent: 2.46,
            media: ['中国新闻网', '中国经济网', '大众网']
          },
          {
            opinion: '香港建制派愤怒谴责暴徒侮辱国旗等行径:无法无天',
            rowkey: '1010582635647056eb8607b51de301',
            source_url: 'http://m.ce.cn/yw/gd/201908/04/t20190804_32803817.shtml',
            count: 19,
            percent: 2.13,
            media: ['大众网', '河北新闻网', '深港在线']
          },
          {
            opinion: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
            rowkey: '1010583747402090e165646ee9f48f',
            source_url: 'http://www.xinhuanet.com/2019-07/22/c_1124780358.htm',
            count: 16,
            percent: 1.79,
            media: ['中国经济网', '大众网', '长城网']
          },
          {
            opinion: '华春莹：严正要求美国等西方国家停止为香港暴力活动撑腰打气',
            rowkey: '10105827486360364430bbca6eed2a',
            source_url: 'http://m.chinanews.com/wap/detail/zw/gn/2019/08-02/8915276.shtml',
            count: 16,
            percent: 1.79,
            media: ['中国新闻网', '大众网', '中国经济网']
          },
          {
            opinion: '香港法院启动审理45人涉嫌暴动、袭警案',
            rowkey: '10105828528580d32a5a34c9c07942',
            source_url: 'http://news.xinhuanet.com/mrdx/2019-08/01/c_138275020.htm',
            count: 16,
            percent: 1.79,
            media: ['中国经济网', '央广网', '四川在线']
          }
        ],
        opinion_gov_main_media: [
          {
            media: '中国经济网',
            count: 141,
            article: [
              {
                title: '梁家辉、谭咏麟…冒雨上街撑警察！',
                rowkey: '10105855457070445dcd96b379d7c6',
                source_url: 'http://m.ce.cn/yw/gd/201907/01/t20190701_32482505.shtml'
              },
              {
                title: '人民日报海外版：香港极端激进分子人心丧尽',
                rowkey: '101058536744705b4f7d4b50936d81',
                source_url: 'http://m.ce.cn/sh/sgg/201907/03/t20190703_32509109.shtml'
              },
              {
                title: '泪目：国旗被香港市民自发重新升起！路过市民敬礼',
                rowkey: '10105853735070fc1f1d745d7eff0e',
                source_url: 'http://www.ce.cn/xwzx/gnsz/gdxw/201908/04/t20190804_32803549.shtml'
              }
            ]
          },
          {
            media: '大众网',
            count: 127,
            article: [
              {
                title: '暴力祸港者必须严惩',
                rowkey: '101058537570506a74d3f65453f54d',
                source_url: 'http://www.dzwww.com/xinwen/shehuixinwen/201907/t20190703_18898124.htm'
              },
              {
                title: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
                rowkey: '101058373063408f7429ecbbbb31d9',
                source_url: 'http://www.dzwww.com/xinwen/shehuixinwen/201907/t20190722_18969966.htm'
              },
              {
                title: '香港法律界人士对法治基础遭破坏感到痛心',
                rowkey: '101058311624703583bc8f449ec152',
                source_url: 'http://news.dzwww.com/guojixinwen/201907/t20190729_18996453.htm'
              }
            ]
          },
          {
            media: '人民网',
            count: 76,
            article: [
              {
                title: '不许乱港！央视今天连播两篇评论',
                rowkey: '10105854046910d2fd0bd35aef9226',
                source_url: 'http://gd.people.com.cn/n2/2019/0702/c123932-33101206.html'
              },
              {
                title: '香港如果发生无法控制局面的暴乱，中央政府肯定会去干预，维持社会稳定的。',
                rowkey: '1010583607244051b722147e013b15',
                source_url: 'http://bbs1.people.com.cn/post/1/1/1/172756376.html'
              },
              {
                title: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
                rowkey: '10105837334520ff2be150679fd89c',
                source_url: 'http://hm.people.com.cn/n1/2019/0722/c42272-31246853.html#liuyan'
              }
            ]
          },
          {
            media: '央广网',
            count: 56,
            article: [
              {
                title: '不许乱港！央视连播两篇评论',
                rowkey: '101058535508705f15b7115ed3b78f',
                source_url: 'http://www.cnr.cn/sd/yw/20190703/t20190703_524677855.shtml'
              },
              {
                title: '再闹下去香港折腾不起(香江观察)',
                rowkey: '10105853442870b9be2f45fcbb2e62',
                source_url: 'http://news.cnr.cn/native/gd/20190801/t20190801_524714607.shtml'
              },
              {
                title: '通讯：“反暴力、护法治、盼安定、促发展”——香港市民的心声',
                rowkey: '1010583809247033bf3b0ee3f69248',
                source_url: 'http://news.cnr.cn/native/gd/20190721/t20190721_524699682.shtml'
              }
            ]
          },
          {
            media: '海外网',
            count: 49,
            article: [
              {
                title: '暴力祸港者必须严惩 ',
                rowkey: '101058538372503e8ea12fd85068cd',
                source_url: 'http://news.haiwainet.cn/n/2019/0703/c3541093-31585699.html'
              },
              {
                title: '人民日报海外版：暴力祸港者必须严惩 ',
                rowkey: '10105853764730ffba6f547205a28e',
                source_url: 'http://news.haiwainet.cn/n/2019/0703/c3541093-31585716.html'
              },
              {
                title: '"警察被暴徒砸砖头，我们受够了！"香港各界呼吁支持警方',
                rowkey: '101058306756805d9c07da86eaf272',
                source_url: 'http://news.haiwainet.cn/n/2019/0730/c3541083-31601195.html'
              }
            ]
          },
          {
            media: '中国新闻网',
            count: 45,
            article: [
              {
                title: '香港警方拘捕49名暴力示威者 44人将被控暴动罪',
                rowkey: '10105829894460690a2bf16eaf4e05',
                source_url: 'https://www.chinanews.com/ga/2019/07-30/8911860.shtml'
              },
              {
                title: '香港警察队员佐级协会主席：有决心继续维护香港治安',
                rowkey: '10105827720260fc7012335d7edf25',
                source_url: 'http://www.chinanews.com/ga/shipin/cns/2019/08-02/news825955.shtml'
              },
              {
                title: '香港各大政团社团一致谴责激进示威者围堵香港中联办暴行',
                rowkey: '10105837467340defc1203b7ef466a',
                source_url: 'http://www.chinanews.com/ga/2019/07-22/8902394.shtml'
              }
            ]
          }
        ],
        opinion_weibo_analysis: {
          total: 64702,
          original: 8913,
          forward: 55789,
          usertype: '普通用户',
          usertype_count: 51829
        },
        opinion_weibo_forward: {
          original: 8913,
          forward: 55789
        },
        opinion_weibo_usertype_list: {
          type: ['普通用户', '达人', '黄V', '蓝V', '金V', '微博女郎'],
          data: [51829, 6105, 4879, 987, 859, 43]
        },
        opinion_weibo_source_manner: [
          {
            opinion:
              '离死人已经很近了，基辅2014当自由广场持续暴乱时，乌克兰军警不敢动手，政客怕死人，会被欧美制裁。结果，指挥者从格鲁吉亚派来杀手，从高楼向示威的傻逼们进行射击，成功恶化局势，基辅火光冲天，一部分军队倒',
            rowkey: '30105830284720382a545790266b48',
            source_url: 'https://weibo.com/6340026081/HFPOwEJ4M',
            count: 238,
            percent: 2.67,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.1100.1100.50/73d3a8f6ly1fv6pt4punij20uk0ukwiy.jpg',
                home: 'https://weibo.com/u/1943251190',
                name: '造星中国',
                type: ''
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.1.640.640.50/4c97dba5jw8esk7gg5widj20hs0hu3za.jpg',
                home: 'https://weibo.com/u/1285020581',
                name: '朱坤岭',
                type: ''
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/8b3521bbjw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2335515067',
                name: '舒ka网',
                type: ''
              }
            ]
          },
          {
            opinion:
              '[中国赞][中国赞][中国赞]//@歌诗天下:台湾购买美国武器并且跟美国煽动计划了香港暴动，望粉圈周知，大是大非面前站国家[作揖][作揖]',
            rowkey: '3010582902813027c68942e46b5067',
            source_url: 'https://weibo.com/1849017844/I064inuEr',
            count: 65,
            percent: 0.73,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.512.512.50/4b5ee9cdly8fwnwal427mj20e80e8aah.jpg',
                home: 'https://weibo.com/u/1264511437',
                name: '李婧童童',
                type: ''
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/56626d63jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1449291107',
                name: '清净传意',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.751.751.50/49e276dbly8frc0302txjj20kv0kvgm3.jpg',
                home: 'https://weibo.com/u/1239578331',
                name: 'antant记录生活',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '《特朗普一句大实话，扎了香港谁人心？》截至环球网发稿前，网络上传出香港反对派听闻特朗普说“香港暴乱”后的极端反应↓。http://t.cn/AiYo7432 ​​​',
            rowkey: '30105827231650bd07309af2112a03',
            source_url: 'https://weibo.com/1645749780/I0pFC0FYa',
            count: 60,
            percent: 0.67,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/005Vev3Zly8fvjbkyl3uuj30ro0ro770.jpg',
                home: 'https://weibo.com/u/5426371315',
                name: '我在用三星手机',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.664.664.50/3dbbda76jw8fcfeifa05oj20ig0igjrz.jpg',
                home: 'https://weibo.com/u/1035721334',
                name: '全球正义盛宴',
                type: ''
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.512.512.50/3bd5f474ly8fxuheulyglj20e80e8t9b.jpg',
                home: 'https://weibo.com/u/1003877492',
                name: '用户jg33ix85tr',
                type: ''
              }
            ]
          },
          {
            opinion:
              '『香港暴动背后是中国拔掉中情局最大海外据点的中美谍战较量！』http://t.cn/AilmWKVg ​',
            rowkey: '301058355829005c08a6419af1d794',
            source_url: 'https://weibo.com/1922578790/HEU0j7JvD',
            count: 57,
            percent: 0.64,
            users: [
              {
                avatar: 'https://tvax4.sinaimg.cn/default/images/default_avatar_male_50.gif',
                home: 'https://weibo.com/u/3058332971',
                name: '18650267100林',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/default/images/default_avatar_male_50.gif?Expires=1564056879&ssig=%2BPiWejPhhg&KID=imgbed,tva',
                home: 'https://weibo.com/u/1010166463',
                name: '南昌土熊',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/6f4b2945ly8g0cwpimzvej20ro0rojtl.jpg?Expires=1564373211&ssig=z7XFnU0Nk0&KID=imgbed,tva',
                home: 'https://weibo.com/u/1867196741',
                name: '勒布朗的wifi',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '#守护香港#【人民锐评：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、"一国两制"的底线乃至人性的底线。香港不能再乱下去，一起向暴力',
            rowkey: '301058263849209113a6379cba4373',
            source_url: 'https://weibo.com/1847123567/I0yUqinYj',
            count: 50,
            percent: 0.56,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.179.179.50/6486a91ajw1ev7ljghtbqj2050050jrc.jpg',
                home: 'https://weibo.com/u/1686546714',
                name: '环球网',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/3c204019ly8fm9q875dpvj20ro0ro75j.jpg',
                home: 'https://weibo.com/u/1008746521',
                name: '京城张宝',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.512.512.50/3ed20e9dly8fszehvaljcj20e80e8754.jpg',
                home: 'https://weibo.com/u/1053953693',
                name: '杨建国',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '『香港"暴动"的本质不是"修例"，而是美国对华阴谋战_警事V言_传送门』http://t.cn/AiWPN88I ​',
            rowkey: '30105843170560a2e56c3c70d2a3b7',
            source_url: 'https://weibo.com/1949772287/HDzdg50qI',
            count: 41,
            percent: 0.46,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.996.996.50/68570126ly8fv9jm3o0kxj20ro0roq3t.jpg',
                home: 'https://weibo.com/u/1750532390',
                name: '奇飘族-茶语mio',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.750.750.50/5baf7248ly8fp8d33qh3bj20ku0ku0th.jpg?Expires=1563331489&ssig=H2C9hEGzFe&KID=imgbed,tva',
                home: 'https://weibo.com/u/1538224712',
                name: 'Amethyst1005',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1080.1080.50/661076ably8g50vwv9pc4j20u00u0jwa.jpg?Expires=1563844318&ssig=T2rvGpTQ8S&KID=imgbed,tva',
                home: 'https://weibo.com/u/1712354987',
                name: '把我留起',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '杨凡《继园台七号》（No. 7 Cherry Lane） 入威尼斯电影节。据之前外媒爆料，很可能是比较少见的动画电影进主竞赛单元。杨凡导演的代表作包括《美少年之恋》《游园惊梦》等，本片是一部香港电影。',
            rowkey: '3010583586971015eb70e9a921af45',
            source_url: 'https://weibo.com/1617566333/HEQShl4La',
            count: 39,
            percent: 0.44,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.46.159.246.246.50/4a185667jw8f8atkodtjmj20c50cbjry.jpg',
                home: 'https://weibo.com/u/1243108967',
                name: '江夏云飞',
                type: ''
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/006DoJKily8foviez80slj30ro0roacq.jpg',
                home: 'https://weibo.com/u/6078969818',
                name: '心飞扬-1984',
                type: ''
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/4b7ce2bbjw1e8qgp5bmzyj2050050aa8.jpg?Expires=1563789827&ssig=nIL1J%2F88%2F6&KID=imgbed,tva',
                home: 'https://weibo.com/u/1266475707',
                name: 'DD_Ulysses',
                type: '普通用户'
              }
            ]
          },
          {
            opinion: '香港法院启动审理45人涉嫌暴动、袭警案 点击可查看更多 http://t.cn/AiY4VCvY ​​​',
            rowkey: '30105828401830fcbe3a1459d00b29',
            source_url: 'https://weibo.com/3761691972/I0cTZ49JO',
            count: 27,
            percent: 0.3,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1006.1006.50/48d15901ly8ffxzeodnqkj20ry0rytau.jpg',
                home: 'https://weibo.com/u/1221679361',
                name: '山东逍遥居士',
                type: '普通用户'
              },
              {
                avatar: 'https://tvax4.sinaimg.cn/default/images/default_avatar_male_50.gif',
                home: 'https://weibo.com/u/1740170647',
                name: '天枫神月心情往事',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/00622tmJly8fdytars8zaj30ro0rpdi3.jpg',
                home: 'https://weibo.com/u/5526939205',
                name: '安蕊包嘚',
                type: '普通用户'
              }
            ]
          },
          {
            opinion: '香港法院启动审理45人涉嫌暴动、袭警案 http://t.cn/AiYARmCf ​',
            rowkey: '30105828549130f63d3108d1efdc1a',
            source_url: 'https://weibo.com/5359873637/I0bikAgeu',
            count: 23,
            percent: 0.26,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/62e215c2jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1658983874',
                name: 'nowmax',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn//crop.0.0.180.180.50/6c2c4090jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1814839440',
                name: 'nbberry',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn//crop.0.0.640.640.50/5d38c88cjw8ehqvitbvk2j20hs0hs0tc.jpg',
                home: 'https://weibo.com/u/1564002444',
                name: '连老师上课狂点名',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '【外交部：要求美方恪守承诺不插手香港事务】日前，美国总统特朗普表示，香港是中国的一部分，中方会自行处理香港出现的骚乱，美方无需提出建议。在今天的中国外交部例行记者会上，发言人华春莹表示，这次特朗普总统',
            rowkey: '301058274544302538d68cf626745e',
            source_url: 'https://weibo.com/1974576991/I0neTEOoI',
            count: 20,
            percent: 0.22,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.12.12.163.163.50/6379cc13gw1f9jt1lfuucj20500503yn.jpg',
                home: 'https://weibo.com/u/1668926483',
                name: '华龙网',
                type: ''
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.179.179.50/6d0e69cdtw1ebdi035qosj2050050mxa.jpg',
                home: 'https://weibo.com/u/1829661133',
                name: '长春都市丽人医院',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/3e1b9afejw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1041996542',
                name: 'Handsome_Tony',
                type: ''
              }
            ]
          }
        ],
        opinion_weibo_forward_manner: [
          {
            opinion:
              '为啥跑？67年暴动后，港英政府还知道弄个十年公建，港府财政盈余2万亿，跑了不是便宜别人？ //@法医Z: 年轻人赶紧移民跑啊？难道还真坐下来跟统治阶级去对抗？傻了？//@来去之间:港府在以经营生意的方',
            rowkey: '30105835357900ddcaedf852095924',
            source_url: 'https://weibo.com/1111681197/HEWswfcrD',
            count: 4920,
            percent: 8.82,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/006qEGJWly8ft1b6zjaiaj30ro0ro3zu.jpg',
                home: 'https://weibo.com/u/5890679144',
                name: '蔡福顺微博',
                type: ''
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.8.145.145.50/40081e3fjw1edpritom5dj2042066glu.jpg',
                home: 'https://weibo.com/u/1074273855',
                name: '赵林-甘露公益',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1342.1342.50/88edec79ly8fjhvqrjtehj211a11bn02.jpg',
                home: 'https://weibo.com/u/2297293945',
                name: '执麦者',
                type: ''
              }
            ]
          },
          {
            opinion:
              '香港，决不容忍这样闹下去！[拳头] #守护香港#【#人民锐评#：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、“一国两制”的底线乃至',
            rowkey: '301058264048403e68c2267b61f10f',
            source_url: 'https://weibo.com/1649470535/I0yGXdqDL',
            count: 2977,
            percent: 5.34,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.409.0.844.844.50/3e7d5965jw1eisfpel5quj21hc0u0tf1.jpg',
                home: 'https://weibo.com/u/1048402277',
                name: '真实如茶',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.664.664.50/006ooSHFly8g0vcl66iobj30ig0iggmr.jpg',
                home: 'https://weibo.com/u/5857359211',
                name: '局老师家种玫瑰花的土',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/4bb9fe49jw1e8qgp5bmzyj2050050aa8.jpg?Expires=1564061182&ssig=j3GXmPhJ2C&KID=imgbed,tva',
                home: 'https://weibo.com/u/1270480457',
                name: '至君宝',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '[太开心][太开心][太开心] 如果香港级别的暴乱发生在资本主义美国，会被怎么处理[思考] http://t.cn/AiYq8gPo @北美崔哥 ​​​ http://gslb.miaopai.com',
            rowkey: '30105826686650e486aeb44f52fd73',
            source_url: 'https://weibo.com/5851185687/I0vCiEdQM',
            count: 954,
            percent: 1.71,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.480.480.50/4ded303djw8edmrutj5koj20dc0dct9w.jpg',
                home: 'https://weibo.com/u/1307390013',
                name: '挑挑宝呗',
                type: ''
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.1242.1242.50/3ba09217jw8f0ebhgqynzj20yi0yiwgy.jpg',
                home: 'https://weibo.com/u/1000378903',
                name: '莲花宝座兰花手1',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/default/images/default_avatar_male_50.gif?Expires=1564909646&ssig=WqhNRNpM4s&KID=imgbed,tva',
                home: 'https://weibo.com/u/1001848154',
                name: '关公提刀巡游',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '明明有人间正道偏偏不走，自己瞎找了二十多年，结果让黄尸找到了暴乱这条不归路，难道沉默的大多数也要甘心被他们裹挟吗？[挖鼻][摊手]//@阿理汗:香港确实应该深入思考一下自己的过去、现在和未来，在历史和',
            rowkey: '30105831146170a928b020e9d63d7f',
            source_url: 'https://weibo.com/2514846743/HFGpL3qUe',
            count: 778,
            percent: 1.39,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.8.1125.1125.50/95e58417ly8fgon78b9ofj20v90vpgnf.jpg',
                home: 'https://weibo.com/u/2514846743',
                name: '八大商人',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.4.1242.1242.50/62d79700ly8flfo13pmmvj20yi0yrgr2.jpg',
                home: 'https://weibo.com/u/1658296064',
                name: '幸运绅士的美好时代',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.512.512.50/61c93b4bly8fqunr0hi3pj20e80e8q3s.jpg',
                home: 'https://weibo.com/u/1640577867',
                name: '心有点蓝',
                type: ''
              }
            ]
          },
          {
            opinion:
              '//@Esports海涛: 点名硬刚了[赞][赞][赞] “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败，煽动台湾民众捞选票，以求连任，中',
            rowkey: '3010582737689035684869b425978f',
            source_url: 'https://weibo.com/1581391555/I0o5m77qT',
            count: 765,
            percent: 1.37,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.640.640.50/e91d99b3jw8eoh3o0gtz6j20hs0hs0tk.jpg',
                home: 'https://weibo.com/u/3911031219',
                name: '扎西达瓦里希',
                type: ''
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.640.640.50/3bd541e5jw8eq426ryk9fj20hs0hsgml.jpg',
                home: 'https://weibo.com/u/1003831781',
                name: '水货专区',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.2.750.750.50/3bbc091cly8fijjdn39ixj20ku0kzwg3.jpg',
                home: 'https://weibo.com/u/1002178844',
                name: '东熙白板中',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '【香港警方拘捕49名暴力示威者 45人将被控罪[拳头]】据人民日报，28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕49人。据了解警方将控告其中4',
            rowkey: '30105829920870485e0035c1cc55ef',
            source_url: 'https://weibo.com/5467852665/HFTMFD1tL',
            count: 580,
            percent: 1.04,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/006F1rksly8g49pbxiepij30ro0roq45.jpg',
                home: 'https://weibo.com/u/6102970152',
                name: '也要开心次火锅',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/6b87d892jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1804064914',
                name: '小冯日记',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.22.1125.1125.50/4ae6899bly8fl9y8i2f1bj20v90whjv0.jpg',
                home: 'https://weibo.com/u/1256622491',
                name: 'Dana_Qiao',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '转发微博 转：他叫廖颂贤，香港国泰航空公司的副机师，星期天在暴乱中被警方逮捕，被控暴乱等罪行。他今天获保释，其他保释人员被禁止离开香港，但这个副机师被允许继续担任他的现职并驾驶飞机离境。他开的飞机爱国',
            rowkey: '3010582769751067038e9da775fc82',
            source_url: 'https://weibo.com/1765598103/I0kAsj8IR',
            count: 437,
            percent: 0.78,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.640.640.50/3e6009b3ly8fqzeqvnop6j20hs0hs3z4.jpg',
                home: 'https://weibo.com/u/1046481331',
                name: '王文波1967',
                type: '达人'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/54ab3da4jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1420508580',
                name: 'lizaa',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/3bb54ddaly8fib8y24v7mj20ro0roq4p.jpg',
                home: 'https://weibo.com/u/1001737690',
                name: '改个名字都这么难123',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '转发微博 #五星红旗有十四亿护旗手#【#国报微评#这才是真正爱香港的香港人】一边是暴徒把国旗扔入海中，一边是#香港市民自发重新升起国旗#；一边是唯恐香港不乱，一边是要在自己家园恢复秩序。两相比较，高下',
            rowkey: '30105825745320c1c419c707c5868d',
            source_url: 'https://weibo.com/5785531314/I0FT78bG0',
            count: 430,
            percent: 0.77,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.996.996.50/3bad6f8bly8fvy77gun3pj20ro0rodi9.jpg',
                home: 'https://weibo.com/u/1001222027',
                name: '我可是九亿少女之一啊',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.512.512.50/75016df9ly8fyb9e5ri4hj20e80e8wez.jpg',
                home: 'https://weibo.com/u/1963027961',
                name: '_JanetW',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.664.664.50/007vu5uPly8fxzlwo2912j30ig0igmyj.jpg',
                home: 'https://weibo.com/u/6878167211',
                name: 'kris201709',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '转发微博 #守护香港#【@人民日报 ：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、"一国两制"的底线乃至人性的底线。香港不能再乱下',
            rowkey: '301058263711908300cd6ae8d37fc1',
            source_url: 'https://weibo.com/1652811601/I0z3If7ji',
            count: 417,
            percent: 0.75,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.202.137.310.310.50/3d4a4ed7gw1eghej11okyj20ku0fmjvq.jpg',
                home: 'https://weibo.com/u/1028280023',
                name: '曹铀',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/3d2627dejw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1025910750',
                name: '萌萌喵喵呜呜',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.667.667.50/3d82f859ly8fleji5etf2j20ij0ij0vd.jpg?Expires=1564914461&ssig=AvZ7esBYud&KID=imgbed,tva',
                home: 'https://weibo.com/u/1031993433',
                name: 'KKKKKellen',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '转发微博 正式官宣香港废青是美国操控的！华春莹驳蓬佩奥涉港言论：近期暴力活动是美方“作品”！\n\nCIA人员被大量拍到指挥香港暴乱，官方终于毫不留情的指出来了，就是美国CIA作品！\n\n问：美国国务卿蓬佩',
            rowkey: '301058292635604481d0c4f03b17de',
            source_url: 'https://weibo.com/2038364675/I03v1ExGq',
            count: 401,
            percent: 0.72,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.720.720.50/3e14f580jw8f0f96plbvcj20k00k03zk.jpg',
                home: 'https://weibo.com/u/1041560960',
                name: 'xiaomuziyangshary',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.80.80.50/3de1f889jw8epcku9a851j2028028q2p.jpg',
                home: 'https://weibo.com/u/1038219401',
                name: '窗缦',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.838.838.50/3e1bb855jw8ekjby4ocpnj20na0nataq.jpg',
                home: 'https://weibo.com/u/1042004053',
                name: '本子007',
                type: '普通用户'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v1: [
          {
            opinion:
              '为啥跑？67年暴动后，港英政府还知道弄个十年公建，港府财政盈余2万亿，跑了不是便宜别人？ //@法医Z: 年轻人赶紧移民跑啊？难道还真坐下来跟统治阶级去对抗？傻了？//@来去之间:港府在以经营生意的方',
            rowkey: '30105835357900ddcaedf852095924',
            source_url: 'https://weibo.com/1111681197/HEWswfcrD',
            count: 94,
            percent: 10.94,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.11.0.1220.1220.50/79594e60ly8fh6ncr9e5kj20yi0xwace.jpg',
                home: 'https://weibo.com/u/2035895904',
                name: '椒江叶Sir',
                type: '金V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.8.145.145.50/40081e3fjw1edpritom5dj2042066glu.jpg',
                home: 'https://weibo.com/u/1074273855',
                name: '赵林-甘露公益',
                type: '金V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.996.996.50/005wYsa4jw8f33qgjfi1zj30ro0rptbq.jpg',
                home: 'https://weibo.com/u/5067914848',
                name: '子午侠士',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '这几天香港暴乱继续，真为香港感动悲哀，已经失去理性，我是不会再去香港了～！ ​​​',
            rowkey: '30105832642780d35d1392261c1a3f',
            source_url: 'https://weibo.com/5066260790/HFq5j5B6l',
            count: 49,
            percent: 5.7,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.103.103.50/ded0593fly1fvg2sgjli7j202w03vweb.jpg',
                home: 'https://weibo.com/u/3738196287',
                name: '开心憨牛',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/dec9813bly8fzl77axyldj20ro0romz9.jpg',
                home: 'https://weibo.com/u/3737747771',
                name: '大熊Rita',
                type: '金V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/6f0ffce8jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1863318760',
                name: '张箫青',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '[太开心][太开心][太开心] 如果香港级别的暴乱发生在资本主义美国，会被怎么处理[思考] http://t.cn/AiYq8gPo @北美崔哥 ​​​ http://gslb.miaopai.com',
            rowkey: '30105826686650e486aeb44f52fd73',
            source_url: 'https://weibo.com/5851185687/I0vCiEdQM',
            count: 18,
            percent: 2.1,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.1125.1125.50/50dcf6f1ly8fvzu11glanj20v90v977n.jpg',
                home: 'https://weibo.com/u/1356658417',
                name: '烟雨楼百年孤独',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.106.32.279.279.50/4c3740bely8ff2bky6ruyj20dw0dz0u3.jpg',
                home: 'https://weibo.com/u/1278689470',
                name: '龙立新Aaron',
                type: '金V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.33.14.508.508.50/717e3b0djw8f33hhn4aigj20fu0eu3zb.jpg',
                home: 'https://weibo.com/u/1904098061',
                name: 'YouTube全球精选',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '杨凡《继园台七号》（No. 7 Cherry Lane） 入威尼斯电影节。据之前外媒爆料，很可能是比较少见的动画电影进主竞赛单元。杨凡导演的代表作包括《美少年之恋》《游园惊梦》等，本片是一部香港电影。',
            rowkey: '3010583586971015eb70e9a921af45',
            source_url: 'https://weibo.com/1617566333/HEQShl4La',
            count: 14,
            percent: 1.63,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.12.1125.1125.50/3e4d8bf2ly8fdzbtfo3q0j20v90vygoc.jpg',
                home: 'https://weibo.com/u/1045269490',
                name: '杜长军',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.8.1125.1125.50/95e58417ly8fgon78b9ofj20v90vpgnf.jpg',
                home: 'https://weibo.com/u/2514846743',
                name: '八大商人',
                type: '金V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.996.996.50/005wYsa4jw8f33qgjfi1zj30ro0rptbq.jpg',
                home: 'https://weibo.com/u/5067914848',
                name: '子午侠士',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '【特朗普一句大实话，扎了港毒的心！】当地时间8月1日，特朗普被记者“堵”在白宫南草坪。记者问：有报道说，中国军队正在为干预香港局势，应对示威者做准备，你对此感到担忧吗？特朗普答：嗯，香港确实好像正发生',
            rowkey: '301058267062903cbbf9be44fa8c63',
            source_url: 'https://weibo.com/2248834985/I0vp17bAp',
            count: 7,
            percent: 0.81,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.11.0.1220.1220.50/79594e60ly8fh6ncr9e5kj20yi0xwace.jpg',
                home: 'https://weibo.com/u/2035895904',
                name: '椒江叶Sir',
                type: '金V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.30.4.254.254.50/7bdaf447tw1eip4o00v8cj209q07cmxr.jpg',
                home: 'https://weibo.com/u/2077946951',
                name: '寂寞嫦娥舒广袖',
                type: '金V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.640.640.50/860a7fa9jw8ei8znvfvqbj20hs0hs760.jpg',
                home: 'https://weibo.com/u/2248834985',
                name: '四川魏静',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '[二哈]//@来去之间://@耿直的MTP:一点也不意外。近些年不是一直在去台湾接受培训么 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败',
            rowkey: '30105827514970baf420d7349842a5',
            source_url: 'https://weibo.com/1864135524/I0mzX5eCc',
            count: 6,
            percent: 0.7,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/8403c2c6ly8fitobz5ol6j20ro0rodl3.jpg',
                home: 'https://weibo.com/u/2214838982',
                name: '开水族馆的生物男',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.512.512.50/92136012ly8fvwiflqgrzj20e80e8mxx.jpg',
                home: 'https://weibo.com/u/2450743314',
                name: 'EricTsui',
                type: '金V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.1.178.178.50/6f1c7364jw8eh7rcupfjcj204y050dfw.jpg',
                home: 'https://weibo.com/u/1864135524',
                name: '电影票房',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '嘿嘿嘿，台湾的钱又是哪里来的呢？//@来去之间://@耿直的MTP:一点也不意外。近些年不是一直在去台湾接受培训么 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香',
            rowkey: '301058275293601acc1c0cb4699fcb',
            source_url: 'https://weibo.com/1657156945/I0mqd7VJI',
            count: 5,
            percent: 0.58,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.12.1125.1125.50/3e4d8bf2ly8fdzbtfo3q0j20v90vygoc.jpg',
                home: 'https://weibo.com/u/1045269490',
                name: '杜长军',
                type: '金V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.179.179.50/5eb88de3jw1eahh6tpg4jj2050050q3g.jpg',
                home: 'https://weibo.com/u/1589153251',
                name: '李成东',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.36.5.471.471.50/40dfde6fly8fqeoai4clzj20eo0d977t.jpg',
                home: 'https://weibo.com/u/1088413295',
                name: 'Easy',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '#特朗普一句大实话扎了香港谁人心# 特朗普:香港是暴乱，香港是中国的一部分，中国会自己处理这个问题！#香港是中国的一部分，中国会自己处理这个问题# 30多名暴乱分子逃往台湾，台当局称暴乱分子为“朋友”',
            rowkey: '30105825521320f28ae2d1bc35bf97',
            source_url: 'https://weibo.com/1074273855/I0IkEgEx1',
            count: 4,
            percent: 0.47,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.8.145.145.50/40081e3fjw1edpritom5dj2042066glu.jpg',
                home: 'https://weibo.com/u/1074273855',
                name: '赵林-甘露公益',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.14.1242.1242.50/c06602e3ly8fjg08fs1hjj20yi0zbdht.jpg',
                home: 'https://weibo.com/u/3227910883',
                name: '盐都小警察',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.4.182.182.50/006V46pbly8fvwxppbkxzj305205lglv.jpg',
                home: 'https://weibo.com/u/6340026081',
                name: '今日大热门',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '内地青年安心建设社会主义新中国，香港青年全力落实英国部署街头旧动荡//@斯巴啊达克斯:哎～ 亲者痛 仇者快。突然想到自己这几天都去深圳图书馆看书，每天都是坐满了人，甚至过道上，楼梯上。今天想着有个位子',
            rowkey: '30105843722250e56b41b42d9eb7e2',
            source_url: 'https://weibo.com/1112928761/HDtc22M7v',
            count: 4,
            percent: 0.47,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.181.181.50/5dcc1f05jw1emoicgqvp5j2052052wep.jpg',
                home: 'https://weibo.com/u/1573658373',
                name: '新爵罗的珠宝猫',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.5.0.739.739.50/53608990ly8fn9ixr82l1j20ku0kj0tq.jpg',
                home: 'https://weibo.com/u/1398835600',
                name: '風中微塵',
                type: '金V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.1000.1000.50/4255f1f9jw8f33m2kyg18j20rs0rsdjj.jpg',
                home: 'https://weibo.com/u/1112928761',
                name: '深圳小天',
                type: '金V'
              }
            ]
          },
          {
            opinion:
              '#菜鸟军虫[超话]#《环球时报》报道【暴乱横行兵凶战危 香港明天在哪？】[怒][话筒][怒][话筒][怒][话筒][怒][话筒][怒][话筒][怒][话筒][怒][话筒]在香港元朗和中环等地，手持自制',
            rowkey: '30105830557120f652e9fdd7396499',
            source_url: 'https://weibo.com/1393202693/HFMQg2EOZ',
            count: 3,
            percent: 0.35,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.5.0.1232.1232.50/aef936b7ly8flgb5c9xrzj20yi0y8qc1.jpg',
                home: 'https://weibo.com/u/2935568055',
                name: '风吟听竹',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1328.1328.50/530a9605ly8fjt58oct3gj210w10w0v0.jpg',
                home: 'https://weibo.com/u/1393202693',
                name: '菜鸟军虫微博',
                type: '金V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.512.512.50/006oRvBCly8ftukzs43e7j30e80e80t3.jpg',
                home: 'https://weibo.com/u/5864181936',
                name: '军事爆料圈',
                type: '金V'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v2: [
          {
            opinion:
              '都是套路，看明白了其实挺反胃的。 看见没，香港警察是每个暴动集会都支持（看图1）。\n也核准了27号黑衣人“光复元朗行动” ，jc被打活该！但 元朗居民反抗，会被jc抓。 这种警队和港府，还需要撑？go',
            rowkey: '30105835271490850b0180ea875422',
            source_url: 'https://weibo.com/1245161127/HEXoZ2Nma',
            count: 642,
            percent: 13.16,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/006qEGJWly8ft1b6zjaiaj30ro0ro3zu.jpg',
                home: 'https://weibo.com/u/5890679144',
                name: '蔡福顺微博',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1342.1342.50/88edec79ly8fjhvqrjtehj211a11bn02.jpg',
                home: 'https://weibo.com/u/2297293945',
                name: '执麦者',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.33.629.629.50/007CmVbCly8fzpyucjyeyj30uv0iimyh.jpg',
                home: 'https://weibo.com/u/6979893636',
                name: '庐陵365',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '//@雪里蕻炖六宝:守护香港 #守护香港#【#人民锐评#：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、“一国两制”的底线乃至人性的',
            rowkey: '30105826416850b46c5e620cd249bf',
            source_url: 'https://weibo.com/5589265547/I0yyP5ROc',
            count: 162,
            percent: 3.32,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/3c204019ly8fm9q875dpvj20ro0ro75j.jpg',
                home: 'https://weibo.com/u/1008746521',
                name: '京城张宝',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.664.664.50/3dcaea02ly8g2zt2djiw1j20ig0igmyj.jpg',
                home: 'https://weibo.com/u/1036708354',
                name: '旅途中的绵羊',
                type: '黄V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/4bb9fe49jw1e8qgp5bmzyj2050050aa8.jpg?Expires=1564061182&ssig=j3GXmPhJ2C&KID=imgbed,tva',
                home: 'https://weibo.com/u/1270480457',
                name: '至君宝',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '我一直强调教育权是国家主权之一。HK的关键问题之一就在此。米国当年在中国办教会学校的目的也在于此。教会学校会产生什么作用，不全面展开说，最清晰的一点是：教会的教育无一例外都是反共的。所以回归后的二十多',
            rowkey: '301058257891006624160c700ee04f',
            source_url: 'https://weibo.com/1093974672/I0Fpvemtg',
            count: 72,
            percent: 1.48,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.996.996.50/9942b9b5ly8few4yqb1msj20ro0rodhx.jpg',
                home: 'https://weibo.com/u/2571286965',
                name: '布哈拉丝绸',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1342.1342.50/88edec79ly8fjhvqrjtehj211a11bn02.jpg',
                home: 'https://weibo.com/u/2297293945',
                name: '执麦者',
                type: '黄V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.640.640.50/860a7fa9jw8ei8znvfvqbj20hs0hs760.jpg',
                home: 'https://weibo.com/u/2248834985',
                name: '四川魏静',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '转发微博 如果香港级别的暴乱发生在资本主义美国，会被怎么处理[思考] http://t.cn/AiYq8gPo @北美崔哥 ​',
            rowkey: '3010582605368059afa5c7333769e2',
            source_url: 'https://weibo.com/2331382571/I0CwvpHoQ',
            count: 45,
            percent: 0.92,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.119.119.50/3f7a3e0cgw1ea5d8lkw2oj203c03c3yk.jpg',
                home: 'https://weibo.com/u/1064975884',
                name: '栾璞',
                type: '黄V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/4465775djw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1147500381',
                name: '宜宾罗鸿',
                type: '黄V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/3e6701a7jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1046938023',
                name: '苏罕',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '#守护香港#【@人民日报 ：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、"一国两制"的底线乃至人性的底线。香港不能再乱下去，一起向',
            rowkey: '301058264135906c2cd7f01a7dc737',
            source_url: 'https://weibo.com/5627430319/I0yB1DJ3h',
            count: 44,
            percent: 0.9,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.106.0.341.341.50/53e48e7bgw1eh633tmde6j20fa09wwfy.jpg',
                home: 'https://weibo.com/u/1407487611',
                name: 'jedicat',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.751.751.50/626bf31dly8fxe99r0v78j20kv0kvq3x.jpg',
                home: 'https://weibo.com/u/1651241757',
                name: 'Royees_爱体育',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/47b7db7bly8fy6qhjarj3j20ro0ro401.jpg',
                home: 'https://weibo.com/u/1203231611',
                name: '阳泉暁東同學',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '转发微博 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败，煽动台湾民众捞选票，以求连任，中央及时取消所有城市赴台自由行，下一步还会有惩罚动',
            rowkey: '30105827539330542578d33d724ded',
            source_url: 'https://weibo.com/6423600287/I0mjtfnYV',
            count: 39,
            percent: 0.8,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.750.750.50/4f5bc10fly8fjydjpengqj20ku0kujs0.jpg',
                home: 'https://weibo.com/u/1331413263',
                name: '拳名自然',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.960.960.50/4c0fb224ly8fni2oo3ejsj20qo0qowg8.jpg',
                home: 'https://weibo.com/u/1276097060',
                name: '-水清木華',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.1242.1242.50/493f6442ly8fptdhc3kzej20yi0yi799.jpg',
                home: 'https://weibo.com/u/1228891202',
                name: '白巧克力邓先生',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '//@人民日报: #守护香港#【香港警方拘捕49名暴力示威者 45人将被控罪】28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕49人。据了解警方将',
            rowkey: '301058298569602c50fbbd70a0f95a',
            source_url: 'https://weibo.com/6210943621/HFUtUC1YJ',
            count: 33,
            percent: 0.68,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.13.0.1095.1095.50/61debbe5ly8fv59r4nwlrj20v60ufabr.jpg',
                home: 'https://weibo.com/u/1641987045',
                name: 'LinAnBingGe',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.664.664.50/514ebfb6ly8fd3ynslp16j20ig0igmyc.jpg',
                home: 'https://weibo.com/u/1364115382',
                name: '荆激鸟早统台湾QQ2958',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.960.960.50/4453c769ly8fnq6ub8g0cj20qo0qoabs.jpg',
                home: 'https://weibo.com/u/1146341225',
                name: '周_Qy',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '不瞒您说，港府的这帮戏子的套路水平，在大陆也就是个边远省份贫困县的县府水平。//@eprom:都是套路，看明白了其实挺反胃的。 看见没，香港警察是每个暴动集会都支持（看图1）。\n也核准了27号黑衣人“',
            rowkey: '3010583526930053d74841b7f8c744',
            source_url: 'https://weibo.com/1245161127/HEXqsCqG5',
            count: 30,
            percent: 0.61,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/4a37a6a7jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1245161127',
                name: 'eprom',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.33.629.629.50/007CmVbCly8fzpyucjyeyj30uv0iimyh.jpg',
                home: 'https://weibo.com/u/6979893636',
                name: '庐陵365',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/4060a1d1ly8fo6kzodcqsj20ro0romzj.jpg',
                home: 'https://weibo.com/u/1080074705',
                name: 'OKei',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '卧槽牛逼啊//@_啊D_: 招多点英国人和美国人做警察，让他们走前线，让美国英国媒体多点跟拍，看看美国和英国的民众怎样的反应，该有力执法还是随便给暴徒打//@石锋强19880101://@李铁军-备胎',
            rowkey: '30105840170440093c150d30f93efd',
            source_url: 'https://weibo.com/3700017784/HE5WPx128',
            count: 25,
            percent: 0.51,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/473a4a0djw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1195002381',
                name: '阿弥陀佛007',
                type: '黄V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.63.158.333.333.50/3d7d0261jw8f811x1q8ekj20b40go0wp.jpg',
                home: 'https://weibo.com/u/1031602785',
                name: '我爱宁河666',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.641.641.50/4cada550ly8fqnx1hflwfj20ht0hugmu.jpg',
                home: 'https://weibo.com/u/1286448464',
                name: '熙妃格格',
                type: '黄V'
              }
            ]
          },
          {
            opinion:
              '【被洗脑的年轻人，活的像个提现木偶】这些港独分子，没文化字都不会写，却能有组织的去进行暴动，必然是因为有坏人在煽动！ 就有不少人在现场拍下，这些指挥和煽动香港暴乱分子的外国人！  #守护香港##香港#',
            rowkey: '30105836188640f4de7b8533957c62',
            source_url: 'https://weibo.com/2492819095/HENowqjBf',
            count: 25,
            percent: 0.51,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.664.664.50/006Opykely8fpi3uayq58j30ig0ig3zl.jpg',
                home: 'https://weibo.com/u/6241703942',
                name: '用户6241703942',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.1125.1125.50/45c6c453ly8fsrxxcz1qoj20v90v9gp7.jpg',
                home: 'https://weibo.com/u/1170654291',
                name: '彻丽_赞比',
                type: '黄V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.33.629.629.50/007CmVbCly8fzpyucjyeyj30uv0iimyh.jpg',
                home: 'https://weibo.com/u/6979893636',
                name: '庐陵365',
                type: '黄V'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v3: [
          {
            opinion:
              '#守护香港#【人民锐评：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、"一国两制"的底线乃至人性的底线。香港不能再乱下去，一起向暴力',
            rowkey: '301058263849209113a6379cba4373',
            source_url: 'https://weibo.com/1847123567/I0yUqinYj',
            count: 63,
            percent: 6.38,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.640.640.50/6e18de6fjw8eswcgtpxvwj20hs0hsgmm.jpg',
                home: 'https://weibo.com/u/1847123567',
                name: '香港商報網',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.179.179.50/6486a91ajw1ev7ljghtbqj2050050jrc.jpg',
                home: 'https://weibo.com/u/1686546714',
                name: '环球网',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.1.0.98.98.50/6250ec47ly8fuqpvhw82sj202s02sjr7.jpg',
                home: 'https://weibo.com/u/1649470535',
                name: '中金在线',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '#降半旗# #香港警方拘捕11人# #李鹏逝世# 一生为国为民鞠躬尽瘁，为改革开放、香港回归、中国崛起付出巨大贡献 #降半旗##香港警方拘捕49人##香港警方拘捕11人# 今天中国大陆各地、中国香港澳',
            rowkey: '301058310909303dd7b8384378c159',
            source_url: 'https://weibo.com/2811223875/HFH184vlM',
            count: 39,
            percent: 3.95,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.1100.1100.50/73d3a8f6ly1fv6pt4punij20uk0ukwiy.jpg',
                home: 'https://weibo.com/u/1943251190',
                name: '造星中国',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.750.750.50/780a2febjw8fc96jsxxy1j20ku0kut9k.jpg',
                home: 'https://weibo.com/u/2013933547',
                name: '上海光牛公司',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/a78fdf43jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2811223875',
                name: '精英汇官方微博',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '#还香港安宁#【香港警方拘捕49名暴力示威者 45人将被控罪】28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕49人。据了解，警方将控告其中45人',
            rowkey: '301058298712606d25229d2b0f99af',
            source_url: 'https://weibo.com/2656274875/HFUkeuiGB',
            count: 32,
            percent: 3.24,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.326.326.50/710c95f3jw8ev7rcivdxpj20930923yx.jpg',
                home: 'https://weibo.com/u/1896650227',
                name: '正义网',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.4.5.173.173.50/705303adly8fsvfup2ujwj2050050weq.jpg',
                home: 'https://weibo.com/u/1884488621',
                name: '中国网网上直播',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/45ffd4d9jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1174394073',
                name: '温江区永盛学校',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '【外交部：要求美方恪守承诺不插手香港事务】日前，美国总统特朗普表示，香港是中国的一部分，中方会自行处理香港出现的骚乱，美方无需提出建议。在今天的中国外交部例行记者会上，发言人华春莹表示，这次特朗普总统',
            rowkey: '301058274544302538d68cf626745e',
            source_url: 'https://weibo.com/1974576991/I0neTEOoI',
            count: 31,
            percent: 3.14,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.12.12.163.163.50/6379cc13gw1f9jt1lfuucj20500503yn.jpg',
                home: 'https://weibo.com/u/1668926483',
                name: '华龙网',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/45ffd4d9jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1174394073',
                name: '温江区永盛学校',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.179.179.50/6d0e69cdtw1ebdi035qosj2050050mxa.jpg',
                home: 'https://weibo.com/u/1829661133',
                name: '长春都市丽人医院',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '【港媒：#香港警方将控告45人# 罪名包括暴动罪】据港媒报道，在28日的香港中西区非法游行后，香港警方拘捕49人。警方30日征询律政司意见后，决定起诉其中45人，被控告人士涉嫌暴动等罪行，明日(31日',
            rowkey: '301058299654402107e2c945ac0376',
            source_url: 'https://weibo.com/3057540037/HFTiwkVGC',
            count: 20,
            percent: 2.03,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/7c54cbbaly8fo0xtn2uipj20ro0ro3zr.jpg',
                home: 'https://weibo.com/u/2085931962',
                name: '贵州综合广播',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/7270de03jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1919999491',
                name: '天府龙泉',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1242.1242.50/7fb00893ly8fvqrqx5l7wj20yi0yiq4n.jpg',
                home: 'https://weibo.com/u/2142242963',
                name: '三峡商报旅游周刊',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '#案件播报#【香港法院启动审理45人涉嫌暴动、袭警案】在港岛西区非法集结中被捕的45名嫌疑犯，因被控暴动、袭警等罪，31日在香港东区裁判法院提堂，45人分为4宗案件提堂。据介绍，在香港东区裁判法院出庭',
            rowkey: '30105828382940518d5aa46185850f',
            source_url: 'https://weibo.com/3268047813/I0d6LfD3g',
            count: 18,
            percent: 1.82,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.179.179.50/c2ca73c5gw1emal4g57srj20500503yj.jpg',
                home: 'https://weibo.com/u/3268047813',
                name: '人民法院报',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/902b5029jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2418757673',
                name: '菏泽成武县法院',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/95dcd803jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2514278403',
                name: '焦作市山阳区法院',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '【[怒]强烈谴责！暴徒竟把香港警察手指钳断】 【[怒]强烈谴责！暴徒竟把香港警察手指钳断】7月13日、14日，香港上水、沙田地区有人制造暴乱，有人提前破坏监控，有人正面用铁棍殴打警员……11名警员受伤',
            rowkey: '301058414976205df50411526ff145',
            source_url: 'https://weibo.com/6342621923/HDRt0bfPd',
            count: 16,
            percent: 1.62,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/7959c6b8jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2035926712',
                name: '平安禹州',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/470ea23bgw1enagd8ucqnj20500503yr.jpg',
                home: 'https://weibo.com/u/1192141371',
                name: '保山政法',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.996.996.50/b90f6e93ly8fqzn5jcas2j20ro0ro0v2.jpg',
                home: 'https://weibo.com/u/3104796307',
                name: '广安政法',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '#守护香港#【香港警方拘捕49名暴力示威者 45人将被控罪】28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕49人。据了解警方将控告其中45人，涉',
            rowkey: '30105829483500fc9ec2c63d2add3d',
            source_url: 'https://weibo.com/3268047813/I016eovOP',
            count: 15,
            percent: 1.52,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.179.179.50/c2ca73c5gw1emal4g57srj20500503yj.jpg',
                home: 'https://weibo.com/u/3268047813',
                name: '人民法院报',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.179.179.50/bdeb7760gw1ebvzhf6pbaj20500500sx.jpg',
                home: 'https://weibo.com/u/3186325344',
                name: '绩溪法院',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/65924326jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1704084262',
                name: '通辽市中级法院',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '【[怒]强烈谴责！暴徒竟把香港警察手指钳断】7月13日、14日，香港上水、沙田地区有人制造暴乱，有人提前破坏监控，有人正面用铁棍殴打警员……11名警员受伤，其中一名被不法分子咬断手指，另一名被钳夹断手',
            rowkey: '3010584236622032244c6018c83a26',
            source_url: 'https://weibo.com/5617030362/HDHZo3r1L',
            count: 14,
            percent: 1.42,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/c86353d2jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/3361952722',
                name: '遂川政法',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.50.35.96.96.50/b4fa0dc1ly8fh1x4xgdz1j204u03rjru.jpg',
                home: 'https://weibo.com/u/3036286401',
                name: '山西大同反邪教',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.2.2.686.686.50/76c46016jw1euctprqf5jj20j60j6gsu.jpg',
                home: 'https://weibo.com/u/1992581142',
                name: '丰南公安网络发言人',
                type: '蓝V'
              }
            ]
          },
          {
            opinion:
              '【香港警方拘捕49名暴力示威者 45人将被控罪】28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕49人。据了解警方将控告其中45人，涉及罪名包括暴',
            rowkey: '301058299290400e4498c288ca052d',
            source_url: 'https://weibo.com/2286908003/HFTH9cHb9',
            count: 14,
            percent: 1.42,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.9.0.2027.2027.50/606580aegw1esysv0gfopj21kw1kwtff.jpg',
                home: 'https://weibo.com/u/1617264814',
                name: '北京晚报北晚新视觉网',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.15.0.144.144.50/68575292gw1ev7xiju4tsj2050050q36.jpg',
                home: 'https://weibo.com/u/1750553234',
                name: '信息时报',
                type: '蓝V'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.1.0.579.579.50/6e1e55acgw1e8an0jk2u1j20gf0gfmyn.jpg',
                home: 'https://weibo.com/u/1847481772',
                name: '攀枝花日报',
                type: '蓝V'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v4: [
          {
            opinion:
              '#香港[超话]#  大家好，我是Yoyo 我就在香港工作生活，最近出了游行和暴动新闻，造成了恐慌，看新闻还以为是叙利亚😂…但是我们一直正常搬砖，正常上学，完全不受影响！放心来港，有什么问题，都可以问我',
            rowkey: '3010583613628015342153fe83bbf6',
            source_url: 'https://weibo.com/3203292543/HENXWuCX7',
            count: 498,
            percent: 8.16,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/4c572d49jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1280781641',
                name: '伊锘',
                type: '达人'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.180.180.50/885b6746jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2287691590',
                name: '渡江兄',
                type: '达人'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/6402fa05jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1677916677',
                name: '安琪-岁月都是渣渣',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '转发微博 #守护香港#【#人民锐评#：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、“一国两制”的底线乃至人性的底线。香港不能再乱下',
            rowkey: '301058263698309f1e767b028181fb',
            source_url: 'https://weibo.com/1713113323/I0z4DcWBB',
            count: 183,
            percent: 3,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.639.639.50/3fe69192jw8ejuzbt4ccij20hs0hrta9.jpg?Expires=1563412030&ssig=JR%2FckP1Qle&KID=imgbed,tva',
                home: 'https://weibo.com/u/1072075154',
                name: '傲骨禅心',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/3fd2aeabjw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1070771883',
                name: '小艾1020',
                type: '达人'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/44c385a9jw1e8qgp5bmzyj2050050aa8.jpg?Expires=1564586403&ssig=pnoYPofSnI&KID=imgbed,tva',
                home: 'https://weibo.com/u/1153664425',
                name: '-超人的超-',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '转发微博 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败，煽动台湾民众捞选票，以求连任，中央及时取消所有城市赴台自由行，下一步还会有惩罚动',
            rowkey: '30105827519010cdcc4608ec48ec7a',
            source_url: 'https://weibo.com/1251889924/I0mxd9rPG',
            count: 101,
            percent: 1.65,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/3bdf19d3ly8frhgm5pjoej20ro0rowgp.jpg',
                home: 'https://weibo.com/u/1004476883',
                name: '坐怀不乱拉链顿',
                type: '达人'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.114.4.480.480.50/4043fbc8jw1e80m1o6g76j20m80goaeg.jpg?Expires=1563441486&ssig=af9q%2FYZvb9&KID=imgbed,tva',
                home: 'https://weibo.com/u/1078197192',
                name: '广州FC吧110yibai风',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.1080.1080.50/485b45eejw8eynanqnha5j20u00u040v.jpg',
                home: 'https://weibo.com/u/1213941230',
                name: '来一桶菜',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '//@食肉獸蜻蜓://@开水族馆的生物男:[二哈]//@来去之间://@耿直的MTP:一点也不意外。近些年不是一直在去台湾接受培训么 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的',
            rowkey: '30105827403280a744339c96a3e399',
            source_url: 'https://weibo.com/1145731012/I0nNvbYt4',
            count: 77,
            percent: 1.26,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1242.1242.50/444a77c4ly8fe8bs24h84j20yi0yigo2.jpg',
                home: 'https://weibo.com/u/1145731012',
                name: '全球时尚四喜',
                type: '达人'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/3c6f2fe1jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1013919713',
                name: '夹了笔的海盗',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.1.0.1240.1240.50/43c3c0bfjw8f4elu0pjxuj20yi0yg76v.jpg',
                home: 'https://weibo.com/u/1136902335',
                name: '湘泽佑一',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '[笑cry][笑cry] 最右扎心//@风闻社区: http://t.cn/AiOw2vcu //@皇家地理学会: 建议香港警务处派人去老东家英国了解一下2010年冬天英国警方是如何处理大学生抗议学费',
            rowkey: '3010585170114006b83680de9edac2',
            source_url: 'https://weibo.com/1211435723/HC48jpjv4',
            count: 69,
            percent: 1.13,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.996.996.50/697d6440jw8f3nwi2wukpj20ro0rpn02.jpg',
                home: 'https://weibo.com/u/1769825344',
                name: '普见文殊',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/4728f9aajw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1193867690',
                name: '中流击水三千里',
                type: '达人'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.748.748.50/3f716891jw8eumyn5r1p3j20ks0kst9u.jpg?Expires=1564914469&ssig=sC2U6cfq2V&KID=imgbed,tva',
                home: 'https://weibo.com/u/1064396945',
                name: '湖湖图图',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '转发微博 如果香港级别的暴乱发生在资本主义美国，会被怎么处理[思考] http://t.cn/AiYq8gPo @北美崔哥 ​​​ http://gslb.miaopai.com/stream/P0G',
            rowkey: '30105826527180a6f2695d464a3889',
            source_url: 'https://weibo.com/1401945490/I0xmbrqqA',
            count: 64,
            percent: 1.05,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.316.43.833.833.50/451315e7ly8fuygbi9pisj215o11lte1.jpg',
                home: 'https://weibo.com/u/1158878695',
                name: '碱迷离',
                type: '达人'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.455.113.1137.1137.50/4a930972jw8eu7c8r4gd8j21kw11x4oy.jpg',
                home: 'https://weibo.com/u/1251150194',
                name: '建武Michael',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1242.1242.50/496bbd0aly8fw1pzotnk1j20yi0yiacd.jpg',
                home: 'https://weibo.com/u/1231797514',
                name: '多博儿',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '【人民日报 ：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、"一国两制"的底线乃至人性的底线。香港不能再乱下去，一起向暴力说不，支持',
            rowkey: '30105826319690e19c8555fc4e52d0',
            source_url: 'https://weibo.com/1732028757/I0zCyfDqT',
            count: 51,
            percent: 0.84,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.202.137.310.310.50/3d4a4ed7gw1eghej11okyj20ku0fmjvq.jpg',
                home: 'https://weibo.com/u/1028280023',
                name: '曹铀',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.751.751.50/4a6fc534ly8fuqiqn9mwbj20kv0kvdiq.jpg',
                home: 'https://weibo.com/u/1248838964',
                name: '库茨卡卡',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.667.667.50/3d82f859ly8fleji5etf2j20ij0ij0vd.jpg?Expires=1564914461&ssig=AvZ7esBYud&KID=imgbed,tva',
                home: 'https://weibo.com/u/1031993433',
                name: 'KKKKKellen',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '27日是个节点，到28日可以明显感觉警方采取措施的力度有所提升，如果司法机构能够默契配合跟上港警的节奏，香港的局势预计会逐步趋于好转。 【港媒：#香港警方将控告45人# 罪名包括暴动罪】据港媒报道，在',
            rowkey: '3010582994438061534946b8ae2e7b',
            source_url: 'https://weibo.com/1790410005/HFTwLC4PA',
            count: 45,
            percent: 0.74,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/62daf590jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1658516880',
                name: '绿色林苑',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.751.751.50/62ec10a5ly8fy1shfkjfzj20kv0kvt9s.jpg',
                home: 'https://weibo.com/u/1659637925',
                name: '奔奔奔王奔奔奔',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/6b87d892jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1804064914',
                name: '小冯日记',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '//@开水族馆的生物男:[二哈]//@来去之间://@耿直的MTP:一点也不意外。近些年不是一直在去台湾接受培训么 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香',
            rowkey: '30105827423900c9fffc93048a8e3a',
            source_url: 'https://weibo.com/1698229384/I0nzyhiVn',
            count: 39,
            percent: 0.64,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.256.256.50/4bf8269dly8fn3sx71u1wj2074074wfr.jpg',
                home: 'https://weibo.com/u/1274554013',
                name: '戮世摩羅史仗義',
                type: '达人'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.512.512.50/3f361319jw8ed9vhm5wk0j20e80e8wf8.jpg',
                home: 'https://weibo.com/u/1060508441',
                name: '沉迷撸猫的耗子',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.1328.1328.50/50a89257ly8fx7cmhl7knj210w10wq61.jpg',
                home: 'https://weibo.com/u/1353224791',
                name: '春暮忽然雨',
                type: '达人'
              }
            ]
          },
          {
            opinion:
              '[哆啦A梦吃惊]//@来去之间://@耿直的MTP:一点也不意外。近些年不是一直在去台湾接受培训么 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国',
            rowkey: '301058274427303b6377788da6850a',
            source_url: 'https://weibo.com/1266434662/I0nmP5kSC',
            count: 32,
            percent: 0.52,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.486.486.50/45599899ly8fxmv8j88cuj20j60j6jtl.jpg',
                home: 'https://weibo.com/u/1163499673',
                name: '乐活丹南',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1328.1328.50/4b7c4266ly8fqgw8rwfl4j210w10wabx.jpg',
                home: 'https://weibo.com/u/1266434662',
                name: 'ikaz求早睡',
                type: '达人'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.996.996.50/4d7ce4c5ly8fo1c4czlhqj20ro0ro0vk.jpg',
                home: 'https://weibo.com/u/1300030661',
                name: 'chensanjin',
                type: '达人'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v5: [
          {
            opinion:
              '//@TT吴雨婷:微博热搜都是些什么，关心关心国家大事不好吗？谁在乎哪个明星结婚了谁又出轨了。香港暴动这么大件事，有多少人知道。 撑住@香港警察 ，中国亿万人民挺你们！#警惕颜色革命# ​​​',
            rowkey: '30105834280940716ebfd19b601852',
            source_url: 'https://weibo.com/1723088164/HF8d5vPhp',
            count: 2,
            percent: 4.65,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.244.25.568.568.50/66b43d24jw8fd5zxrp773j20sg0lcqbg.jpg?Expires=1562564165&ssig=eXQC85vrVr&KID=imgbed,tva',
                home: 'https://weibo.com/u/1723088164',
                name: '主小羊Faye',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '迟早自己害死自己[弱]//@piyo巴卫酱:一群渣青，真想拿把机关枪突突死他们//@不啊可能是颖爷:就连一个英国老爷爷都能明白的道理，这次真的是给人开眼界了，香港经济是内地在带动[作揖]这次暴动是给香',
            rowkey: '3010584296323059d65b19f47d8792',
            source_url: 'https://weibo.com/2612315427/HDBtw6FKA',
            count: 2,
            percent: 4.65,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.1002.1002.50/81aabb06ly8fxkecs3wylj20ru0ruwi4.jpg',
                home: 'https://weibo.com/u/2175449862',
                name: 'JR-HONG',
                type: '微博女郎'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1080.1080.50/9bb4c523ly8g4jn7gk715j20u00u0dim.jpg?Expires=1563260862&ssig=p7ryHjDZwm&KID=imgbed,tva',
                home: 'https://weibo.com/u/2612315427',
                name: 'WingLam17',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '转发微博 #香港九龙暴动#港府在干嘛。。纵容他们每个星期换地方“和平示威”？再这样下去。。香港要凉了？？ ​​​',
            rowkey: '30105849989500180e2ad7ca2916b4',
            source_url: 'https://weibo.com/1723088164/HCmOenBpi',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.244.25.568.568.50/66b43d24jw8fd5zxrp773j20sg0lcqbg.jpg?Expires=1562564165&ssig=eXQC85vrVr&KID=imgbed,tva',
                home: 'https://weibo.com/u/1723088164',
                name: '主小羊Faye',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion: '转发微博 #香港暴动#  网民传来：废青齐集了500人要去元朗报仇[允悲] ​​​',
            rowkey: '30105837248420574e7064879c7249',
            source_url: 'https://weibo.com/1684998072/HEBPA9sRi',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.512.512.50/646f07b8ly8fwraf0827rj20e80e8wet.jpg',
                home: 'https://weibo.com/u/1684998072',
                name: 'Tiramisu不好吃',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '转发微博 【香港一机长参与暴动却仍执飞 民众抗议：不放心把命交到暴徒手上】香港国泰航空一名副机长廖颂贤参与7月28日上环暴动被控暴动罪，昨日（1日）提堂却获准保释，并可继续执飞，引发全港市民哗然。多名',
            rowkey: '30105827343550fd025a25ceb3eee6',
            source_url: 'https://weibo.com/1581979332/I0orUEMRe',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.1242.1242.50/5e4b16c4ly8fvpb3sj5rhj20yi0yi403.jpg',
                home: 'https://weibo.com/u/1581979332',
                name: '靜yyyes',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '没有去过伊拉克，所以不知道战争是何面貌。没有见过香港动乱，所以不知道暴动是何模样，杀破狼里也说过，没经手照料过重病垂死之人，还以为自己身上蹭破的油皮是重伤，没灌一口黄沙砾砾，总觉得金戈铁马只是个威风凛',
            rowkey: '30105830750010bbc3f66c2a14afe8',
            source_url: 'https://weibo.com/2588585861/HFKJLvmJv',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.512.512.50/9a4aaf85ly8fqy73vln5ij20e80e8mxa.jpg',
                home: 'https://weibo.com/u/2588585861',
                name: '黄黄Nina',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '昨天偶遇了这些狗东西暴动。。桥底辣蟹都没吃成！！香港太乱了！我要肥家~[微笑]  ​',
            rowkey: '30105826027400da39106c93a16762',
            source_url: 'https://weibo.com/2391402180/I0COhBOQC',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.20.1242.1242.50/8e89e6c4ly8fmwm362lcwj20yi0zmtci.jpg?Expires=1564899115&ssig=cydtZYkG2c&KID=imgbed,tva',
                home: 'https://weibo.com/u/2391402180',
                name: '大饼妹阿_',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '#香港暴动#香港暴乱这么厉害竟然一丁点儿新闻都没有，本来还想去耍一下，算了保命要紧...看那群港独能蹦跶到什么时候 ​​​',
            rowkey: '30105843128470a44a5006c562ee15',
            source_url: 'https://weibo.com/1783607374/HDzFJ1qVT',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.996.996.50/6a4fb04ely8fdzfkxue2ej20ro0rodi0.jpg',
                home: 'https://weibo.com/u/1783607374',
                name: '曾曾曾繁悦_zy',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '优越感满满的香港青年炮灰们对此浑然不知 “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败，煽动台湾民众捞选票，以求连任，中央及时取消所有城市',
            rowkey: '3010582742631045f09f2cb103a80d',
            source_url: 'https://weibo.com/2460212300/I0nxVf2yR',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.667.667.50/92a3dc4cly8fx4hrlcq06j20ij0ij0u8.jpg',
                home: 'https://weibo.com/u/2460212300',
                name: 'xin-xInXinxiN',
                type: '微博女郎'
              }
            ]
          },
          {
            opinion:
              '[费解]//@蓝色柚子-为德国低调攒RP:[费解]//@军武菌:薮不出话[允悲] 【英籍港警下令发射催泪瓦斯  香港暴徒：外国势力可耻[费解][允悲]】目前，香港有大约60名外籍警察，主要为英籍，近期',
            rowkey: '30105840493610f933a4770a5a88fb',
            source_url: 'https://weibo.com/1733599962/HE2qd34AJ',
            count: 1,
            percent: 2.33,
            users: [
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.11.1242.1242.50/6754a2daly8fed11e10rbj20yi0z441l.jpg',
                home: 'https://weibo.com/u/1733599962',
                name: 'Yvonne是yI朶妞',
                type: '微博女郎'
              }
            ]
          }
        ],
        opinion_weibo_usertype_v6: [
          {
            opinion:
              '看见没，香港警察是每个暴动集会都支持（看图1）。也核准了27号黑衣人“光复元朗行动” ，jc被打活该！但 元朗居民反抗，会被jc抓。 这种警队和港府，还需要撑？go the hell  乱的根源在哪里',
            rowkey: '30105835273820c1fa64f8d1d518ed',
            source_url: 'https://weibo.com/2338793152/HEXnpfURU',
            count: 3883,
            percent: 7.49,
            users: [
              {
                avatar:
                  'https://tvax3.sinaimg.cn/crop.0.0.664.664.50/b37fca75ly8fj9vz1htasj20ig0igdhm.jpg',
                home: 'https://weibo.com/u/3011496565',
                name: '闲逸散人MAO',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.664.664.50/005HPtd1ly8fvtysj1gh2j30ig0igtaj.jpg',
                home: 'https://weibo.com/u/5228313619',
                name: '李静雪99952',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.180.180.50/8b6726c0jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2338793152',
                name: 'ic4fun',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '#早安# 【人民锐评：香港，决不容忍这样闹下去】煽惑社会骚乱，发起暴力冲击，香港部分激进示威者肆意妄为、得寸进尺，正不断突破法治的底线、“一国两制”的底线乃至人性的底线。香港不能再乱下去，一起向暴力说',
            rowkey: '30105826080230e4ee488303606f13',
            source_url: 'https://weibo.com/1736574887/I0Ceyilwd',
            count: 2613,
            percent: 5.04,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.409.0.844.844.50/3e7d5965jw1eisfpel5quj21hc0u0tf1.jpg',
                home: 'https://weibo.com/u/1048402277',
                name: '真实如茶',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.664.664.50/006ooSHFly8g0vcl66iobj30ig0iggmr.jpg',
                home: 'https://weibo.com/u/5857359211',
                name: '局老师家种玫瑰花的土',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.640.640.50/006VMYB4ly8flfe9uv8yej30hs0hsgmz.jpg',
                home: 'https://weibo.com/u/6350720826',
                name: '滚滚儿团一团',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '如果香港级别的暴乱发生在资本主义美国，会被怎么处理[思考] http://t.cn/AiYq8gPo @北美崔哥 ​​​ http://gslb.miaopai.com/stream/P0Gfo03e',
            rowkey: '301058268180107873c3ce33c0e7b4',
            source_url: 'https://weibo.com/2881776970/I0ubrfn99',
            count: 834,
            percent: 1.61,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.480.480.50/4ded303djw8edmrutj5koj20dc0dct9w.jpg',
                home: 'https://weibo.com/u/1307390013',
                name: '挑挑宝呗',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.1242.1242.50/3ba09217jw8f0ebhgqynzj20yi0yiwgy.jpg',
                home: 'https://weibo.com/u/1000378903',
                name: '莲花宝座兰花手1',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax3.sinaimg.cn/default/images/default_avatar_male_50.gif?Expires=1564909646&ssig=WqhNRNpM4s&KID=imgbed,tva',
                home: 'https://weibo.com/u/1001848154',
                name: '关公提刀巡游',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '#守护香港# #香港警察# #还香港安宁# 补充说明：在看完今天微博图文直播之后，估计很多朋友会觉得废青们是不是每天来搞笑的，就这么弱鸡？其实，我想告诉大家的是，我是为了让大家在紧张的气氛中能够轻松点',
            rowkey: '301058315297503fc044c9d4d7a2c9',
            source_url: 'https://weibo.com/6078969818/HFCegpdfU',
            count: 656,
            percent: 1.27,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.355.99.668.668.50/8f5b1a16jw8esejdh9akaj20sg0lcdnz.jpg',
                home: 'https://weibo.com/u/2405112342',
                name: 'lovestar花园',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.46.159.246.246.50/4a185667jw8f8atkodtjmj20c50cbjry.jpg',
                home: 'https://weibo.com/u/1243108967',
                name: '江夏云飞',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.4.1242.1242.50/62d79700ly8flfo13pmmvj20yi0yrgr2.jpg',
                home: 'https://weibo.com/u/1658296064',
                name: '幸运绅士的美好时代',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '[哆啦A梦吃惊][吃瓜] “香港这次暴乱国家已查明有大部分资金是台湾民进党蔡英文资助的，目的是要配合美国搞乱香港，然后攻击一国两制失败，煽动台湾民众捞选票，以求连任，中央及时取消所有城市赴台自由行，下',
            rowkey: '301058275706602c13094851d21b99',
            source_url: 'https://weibo.com/1811140934/I0lYgCHlB',
            count: 624,
            percent: 1.2,
            users: [
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.640.640.50/e91d99b3jw8eoh3o0gtz6j20hs0hs0tk.jpg',
                home: 'https://weibo.com/u/3911031219',
                name: '扎西达瓦里希',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.640.640.50/3bd541e5jw8eq426ryk9fj20hs0hsgml.jpg',
                home: 'https://weibo.com/u/1003831781',
                name: '水货专区',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.2.750.750.50/3bbc091cly8fijjdn39ixj20ku0kzwg3.jpg',
                home: 'https://weibo.com/u/1002178844',
                name: '东熙白板中',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '//@-RM-://@紫光阁:【香港警方拘捕49名暴力示威者 45人将被控罪[拳头]】据人民日报，28日发生在港岛西区的非法游行集会活动中，激进示威者用极端暴力手段冲击警方。香港警方在此次行动中共拘捕',
            rowkey: '301058295534105e9cb6894327bdfa',
            source_url: 'https://weibo.com/1896680034/I00kWbDq3',
            count: 488,
            percent: 0.94,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/006F1rksly8g49pbxiepij30ro0roq45.jpg',
                home: 'https://weibo.com/u/6102970152',
                name: '也要开心次火锅',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.664.664.50/3c753913ly8fpbm5il65dj20ig0iggnm.jpg',
                home: 'https://weibo.com/u/1014315283',
                name: '刕叒呗',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.22.1125.1125.50/4ae6899bly8fl9y8i2f1bj20v90whjv0.jpg',
                home: 'https://weibo.com/u/1256622491',
                name: 'Dana_Qiao',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '转：他叫廖颂贤，香港国泰航空公司的副机师，星期天在暴乱中被警方逮捕，被控暴乱等罪行。他今天获保释，其他保释人员被禁止离开香港，但这个副机师被允许继续担任他的现职并驾驶飞机离境。他开的飞机爱国人士们敢坐',
            rowkey: '30105828078980ed6f92f00949bb1d',
            source_url: 'https://weibo.com/1703721847/I0gqogige',
            count: 399,
            percent: 0.77,
            users: [
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.640.640.50/3e6009b3ly8fqzeqvnop6j20hs0hs3z4.jpg',
                home: 'https://weibo.com/u/1046481331',
                name: '王文波1967',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/54ab3da4jw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/1420508580',
                name: 'lizaa',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.996.996.50/3bb54ddaly8fib8y24v7mj20ro0roq4p.jpg',
                home: 'https://weibo.com/u/1001737690',
                name: '改个名字都这么难123',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '永远是中国不可分割的一部分 #五星红旗有十四亿护旗手#【#国报微评#这才是真正爱香港的香港人】一边是暴徒把国旗扔入海中，一边是#香港市民自发重新升起国旗#；一边是唯恐香港不乱，一边是要在自己家园恢复秩',
            rowkey: '30105825764460f5ec3f2e374d525f',
            source_url: 'https://weibo.com/6550050710/I0FGagCGi',
            count: 389,
            percent: 0.75,
            users: [
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.720.720.50/3be12efdjw8eg9qwicx7qj20k00k0jrj.jpg',
                home: 'https://weibo.com/u/1004613373',
                name: '張鎵寧1021',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax1.sinaimg.cn/crop.0.0.512.512.50/75016df9ly8fyb9e5ri4hj20e80e8wez.jpg',
                home: 'https://weibo.com/u/1963027961',
                name: '_JanetW',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax4.sinaimg.cn/crop.0.0.664.664.50/007vu5uPly8fxzlwo2912j30ig0igmyj.jpg',
                home: 'https://weibo.com/u/6878167211',
                name: 'kris201709',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '//@贪吃的小西几jj: @二婷婷er 【[怒]港独分子为何如此残暴，来自哪里？[话筒]】#香港警方拘捕49人# \n\n暴乱港独大多不是中国人而是前南越船民难民的后代。别忘了，港独打头阵的青壮年都是前南',
            rowkey: '3010582949354034369742e618f171',
            source_url: 'https://weibo.com/3172327100/I00ZrjfiZ',
            count: 374,
            percent: 0.72,
            users: [
              {
                avatar:
                  'https://tva3.sinaimg.cn/crop.0.0.180.180.50/92af973ejw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2460981054',
                name: '茗小猫',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tvax2.sinaimg.cn/crop.0.0.751.751.50/6baf3ddfly8fulvmb5uh6j20kv0kv757.jpg',
                home: 'https://weibo.com/u/1806646751',
                name: '小星的壳壳',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva4.sinaimg.cn/crop.0.0.180.180.50/a55bd0bdjw1e8qgp5bmzyj2050050aa8.jpg',
                home: 'https://weibo.com/u/2774257853',
                name: '柯林斯公爵',
                type: '普通用户'
              }
            ]
          },
          {
            opinion:
              '正式官宣香港废青是美国操控的！华春莹驳蓬佩奥涉港言论：近期暴力活动是美方“作品”！CIA人员被大量拍到指挥香港暴乱，官方终于毫不留情的指出来了，就是美国CIA作品！问：美国国务卿蓬佩奥在回应有关香港的',
            rowkey: '301058299757302c6e99bf5519fef2',
            source_url: 'https://weibo.com/2130122232/HFTbyCqjY',
            count: 363,
            percent: 0.7,
            users: [
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.720.720.50/3e14f580jw8f0f96plbvcj20k00k03zk.jpg',
                home: 'https://weibo.com/u/1041560960',
                name: 'xiaomuziyangshary',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva1.sinaimg.cn/crop.0.0.80.80.50/3de1f889jw8epcku9a851j2028028q2p.jpg',
                home: 'https://weibo.com/u/1038219401',
                name: '窗缦',
                type: '普通用户'
              },
              {
                avatar:
                  'https://tva2.sinaimg.cn/crop.0.0.838.838.50/3e1bb855jw8ekjby4ocpnj20na0nataq.jpg',
                home: 'https://weibo.com/u/1042004053',
                name: '本子007',
                type: '普通用户'
              }
            ]
          }
        ],
        opinion_weibo_leader: [
          {
            article: [
              {
                title: '#香港暴动#分享一段港媒没有发出的元朗事件视频 http://t.cn/Ailmcyy1 ​',
                source_url: 'https://weibo.com/1868358807/HEHIfol4u',
                rowkey: '3010583670937013dcb0311d032e61'
              },
              {
                title:
                  '#香港暴动#爱国议员何君尧亡母墓碑被GD毁坏，GD真是无人性！微博发不了视频，今晚我会在Qun里发布图二的那段节目，看了真的很生气，想看的可以来我主页加VX ​',
                source_url: 'https://weibo.com/1868358807/HEOH9hpgW',
                rowkey: '3010583606946089298622d7557555'
              },
              {
                title:
                  '#香港暴动#白衣服是元朗市民，黑衣服是GD，香港不该是这样的。。。 http://t.cn/AilllVUO ​',
                source_url: 'https://weibo.com/1868358807/HEyxVsgof',
                rowkey: '301058375494603d949280a4415296'
              }
            ],
            name: '悠游记',
            avatar:
              'https://tvax4.sinaimg.cn/crop.0.0.1080.1080.50/6f5ce497ly8fz42uux3ksj20u00u03zk.jpg',
            count: 17,
            type: '黄V',
            home: 'https://weibo.com/u/1868358807'
          },
          {
            article: [
              {
                title:
                  '在香港警察实在没有能力镇压暴乱分子时，香港特区政府行政长官可报请中央政府派出驻港部队协助香港警察缉拿暴乱港独分子，还予香港人民以安定生活！ ​​​',
                source_url: 'https://weibo.com/3930272588/HEQKzx6DC',
                rowkey: '301058358811103d9394e677b5a293'
              },
              {
                title:
                  '在香港警察实在没有能力镇压暴乱分子时，香港特区政府行政长官可报请中央政府派出驻港部队协助香港警察缉拿暴乱港独分子，还予香港人民以安定生活！ ​​​',
                source_url: 'https://weibo.com/3930272588/HEQHAwK0m',
                rowkey: '3010583588551050162fae9e57de68'
              },
              {
                title:
                  '中国香港不容暴乱分子无视法纪，肆无忌惮进行破坏活动、扰乱社会秩序，肆意妄为，践踏香港法治，破坏香港的繁荣稳定，坚决维护国家统一，坚决确保一国两制在香港的成功执行，反对一切外界势力对我国内政的干预，依法',
                source_url: 'https://weibo.com/3930272588/HEO2Z87Gt',
                rowkey: '3010583612884074a582b61fda0e36'
              }
            ],
            name: '深圳政治保卫部',
            avatar:
              'https://tvax2.sinaimg.cn/crop.0.0.1080.1080.50/ea43334cly8g067x14ysdj20u00u00ya.jpg',
            count: 13,
            type: '普通用户',
            home: 'https://weibo.com/u/3930272588'
          },
          {
            article: [
              {
                title:
                  '#祖国万岁##守护香港##还香港安宁# 最后的话：从昨天下午三点一直持续到现在的香港暴乱依然没有彻底结束，虽然大多数地区已经得到了控制，但还有少量废青暴徒在四处骚扰破坏，在过去的十个小时里所发生的一切',
                source_url: 'https://weibo.com/6078969818/I0Azo69JU',
                rowkey: '30105826232720565f5d49c69887f7'
              },
              {
                title:
                  '#香港##元朗##守护香港##元朗群众厉害了# 外媒踢爆美国CIA参入煽动香港暴乱，香港反对派收钱办事甘为洋奴走狗[话筒] http://t.cn/AijP7frJ ​​​ http://f.us.s',
                source_url: 'https://weibo.com/6078969818/HF4oaluC2',
                rowkey: '3010583463115003d081b6a3356fab'
              },
              {
                title:
                  '#香港废青# #还香港安宁# #文盲大革命# 连美国也有人看不下去外媒将香港暴乱视频掐头掐尾，扭曲事实为暴徒们涂脂抹粉[并不简单]http://t.cn/AiYCR32d ​​​ http://gsl',
                source_url: 'https://weibo.com/6078969818/I0uXtd8TN',
                rowkey: '3010582674701003785da800544a52'
              }
            ],
            name: '心飞扬-1984',
            avatar:
              'https://tvax4.sinaimg.cn/crop.0.0.996.996.50/006DoJKily8foviez80slj30ro0roacq.jpg',
            count: 13,
            type: '普通用户',
            home: 'https://weibo.com/u/6078969818'
          },
          {
            article: [
              {
                title:
                  '#守护香港#香港为什么出现暴动事件？如果查不出具体原因，暴动事件在其他地区会不会扩散？国防安全不是儿戏！  ​​​',
                source_url: 'https://weibo.com/7049792075/HFMEQEp6W',
                rowkey: '3010583057397004b60a60ef4d0e20'
              },
              {
                title: '#守护香港#香港为什么发生暴动？大陆有没有地下暴动势力？如何辨别？  ​',
                source_url: 'https://weibo.com/7049792075/HFUKWqhW2',
                rowkey: '30105829831780cf04e7346e7d2f1e'
              },
              {
                title:
                  '#守护香港#香港暴动事件，国防安全警惕在哪里？大陆有没有地下暴动分子？为什么在工厂上班的时候，老板和私营企业内部管理人员行为异常？没有人重视。  ​​​',
                source_url: 'https://weibo.com/7049792075/HFQkcvWBB',
                rowkey: '301058302379007d97604fc3b3bedf'
              }
            ],
            name: '杨安心999',
            avatar:
              'https://tvax2.sinaimg.cn/crop.0.0.664.664.50/007H6cXNly8g1glke8mykj30ig0igmy0.jpg',
            count: 13,
            type: '普通用户',
            home: 'https://weibo.com/u/7049792075'
          },
          {
            article: [
              {
                title:
                  '#你就是那颗闪亮的星##香港警察##守护香港##还香港安宁##挺香港警察护香港安宁# 【有胆识！有担当！光头警长为保护同事，把枪对准暴乱分子！[赞][赞][赞]】港媒按惯例把视频掐头去尾、有选择性的报',
                source_url: 'https://weibo.com/1943251190/I03esuI5E',
                rowkey: '301058292880402b4fca34e64dc5fd'
              },
              {
                title:
                  '#你就是那颗闪亮的星# 【一位不屈服于港毒黄屎废青港残的老人！】恃强凌弱，欺负弱老，这就是美英指使的暴乱分子的本领！道德败坏，低级下作，丧心病狂，就是他们的本质！无赖、无耻、下作、下流、卑鄙、卑劣，已',
                source_url: 'https://weibo.com/1943251190/HFmYvgTgM',
                rowkey: '30105832927780bc49d74467f617a0'
              },
              {
                title:
                  '#您怎么看[超话]# 【港毒、黄屎、废青，原来真是一群废物！】香港暴乱分子频写丑字、别字、错字。仇、撤、代、墙、警、厕、板、盆、箱、……这么多常用字都不会写！水平还不如小学生！【网友评论：笨蛋、弱智、',
                source_url: 'https://weibo.com/1943251190/HFdoW6f7s',
                rowkey: '30105833805180d8fb79929a7354aa'
              }
            ],
            name: '造星中国',
            avatar:
              'https://tvax1.sinaimg.cn/crop.0.0.1100.1100.50/73d3a8f6ly1fv6pt4punij20uk0ukwiy.jpg',
            count: 12,
            type: '蓝V',
            home: 'https://weibo.com/u/1943251190'
          },
          {
            article: [
              {
                title:
                  '【沙田暴乱 | 医管局：至少28伤2危4严重】香港反对修订《逃犯条例》示威昨晚(14日)在沙田新城市广场内，演变成暴力流血冲突。有暴徒肆意挑衅警方、袭击警员，酿成至少28人受伤，其中22人仍留院，当中',
                source_url: 'https://weibo.com/2632236847/HDyeUvpDW',
                rowkey: '30105843259750a50dc3123e6925d2'
              },
              {
                title:
                  '【内地AI识别系统可凭步姿“认人” ，他日引进，香港蒙面暴徒再难逃法网】乱港分子接连发动暴力冲击，口罩、头盔成了他们隐藏身份的工具。“蒙面暴徒”始于2016年旺角暴动，通过戴口罩或蒙面以逃避警方追捕。',
                source_url: 'https://weibo.com/2632236847/HDHck1IUL',
                rowkey: '30105842438750d882ad98d07da715'
              },
              {
                title:
                  '【吴秋北：反对派企图策划“无差别恐袭”可耻 冀警方严正执法】香港工联会会长吴秋北今日(2日)在社交平台表示，警方昨晚踢爆“港独”的一个武器库，可能只是反对派策动连场暴动的冰山一角，属严重罪行，极大威胁',
                source_url: 'https://weibo.com/2632236847/I0ljdddw7',
                rowkey: '301058276313505472ca77401b2c7b'
              }
            ],
            name: '香港文匯網',
            avatar:
              'https://tva2.sinaimg.cn/crop.0.0.180.180.50/9ce4bf2fjw1e8qgp5bmzyj2050050aa8.jpg',
            count: 12,
            type: '蓝V',
            home: 'https://weibo.com/u/2632236847'
          }
        ],
        opinion_weixin_analysis: {
          total: 14133
        },
        opinion_weixin_media: [
          {
            opinion: '来围观：看当年“皇家”警察如何处置“和平”示威者！',
            rowkey: '20105855059600c3a43d3d5ec9c2ba',
            source_url: 'https://mp.weixin.qq.com/s/nidPhtYVXoyJynV0ExVtWw',
            count: 11502,
            percent: 81.38,
            media: ['港澳行', '海外网', '政经解读']
          },
          {
            opinion: '背靠祖国，香港才有光明前景！',
            rowkey: '20105855483320fcd056d0a72e015d',
            source_url: 'https://mp.weixin.qq.com/s/tj04042BmMB8BJZhI4w_bg',
            count: 352,
            percent: 2.49,
            media: ['文明延庆', '凤凰东方财经', '参考时政']
          },
          {
            opinion: '林郑再次会见传媒 回应修例、特赦等香港社会热点问题',
            rowkey: '201058552463209dc78a663d9f9b5f',
            source_url: 'https://mp.weixin.qq.com/s/cls35Ac4z539mh2nxfcoig',
            count: 292,
            percent: 2.07,
            media: ['通说', 'A为民讲百姓时事', 'A每日新爆料']
          },
          {
            opinion: '是该严惩这些暴徒了！',
            rowkey: '2010585301080011dc2658b0131422',
            source_url:
              'https://weixin.sogou.com/api/share?timestamp=1562198786&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO5da4bc-KzKRg9P1OgPLRKSjyUtguOtIYyKuxCZDlXNTU1SyG*NvNrNSBjexe9HQfvYaL8BOcvEq6mJgQqhNBh*v93b7rMpkR5fAgEPxYXg0uDXQr*0quFsPLkBkkTXljvw1HjYZHxrydQNTP53bmP55Kj*hpk4KiM*o4LjSY9ww=',
            count: 221,
            percent: 1.56,
            media: ['昆仑策研究院', '环球时报', '万宁香港']
          },
          {
            opinion: '“港独”毒人',
            rowkey: '20105852681050125d2d9c54489bea',
            source_url: 'https://mp.weixin.qq.com/s/rj-5FCgKpAmIDNTmCJuy8Q',
            count: 142,
            percent: 1,
            media: ['马承钧说古论今', '环球时报', '警魂']
          },
          {
            opinion: '香江谍战较量！（好文推荐）',
            rowkey: '20105846455830f3c960c2d6565680',
            source_url:
              'https://weixin.sogou.com/api/share?timestamp=1562846318&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOzYHXLSGJNxtS2qOrAevyUWC1ethEcFzJawoo2xclcnxWDPbqA2RdxQQl9MVqT23fUIeN8Pl4lhEd6dSQHYB1cir5AhhCCw4pS1ES8VPws4E4mlI8-0VwANpMOASnwWhLSN3QpX7rKARAXQXELtwRaGI860Nwy3PIq6W9YLXTcBk=',
            count: 99,
            percent: 0.7,
            media: ['天下军讯', '实话真讲', '每日黑幕爆料']
          },
          {
            opinion: '怎样反思发生在香港的这场爆乱?',
            rowkey: '201058507810707f7170e1c2b133ee',
            source_url:
              'https://weixin.sogou.com/api/share?timestamp=1562411082&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAORK5BOuXWfReY9raypa5YNlIJ9YKA*hqg2ftfdPtYEs1Omtab-KIHuNwts4h66uooI3g6b7HXttvrAtvSxOuuIeZGN7FzVxW5adUoCSI2IowuTxSNmGoyZWrVyMd-bIo4uQvRgS-1VTLv*uDBeXliXkYc92sv4zpJXhBQJ77rD-8=',
            count: 89,
            percent: 0.63,
            media: ['17墨龙', 'A天下实事爆料', 'cqsxkj']
          },
          {
            opinion:
              '搞乱香港的美国幕后组织被挖出，操盘反华事件，进行颠覆活动，索罗斯参与其中 指挥袭击香港警察的外国人身份曝光！',
            rowkey: '20105839532470def301573460c6f8',
            source_url:
              'https://weixin.sogou.com/api/share?timestamp=1563548370&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOsTmzdL0cBEeAA2j0xmPg5jwj5keLWqpNCoQc2xU4uAZ1D8a7vPGAsHmn7axRrYbDXQIa7NGV*a9HPbXYbxXyTmiDGFb*b9KI1ZCIFZrf9uUtglr**pb8gNi9ErFjGetC15e1g7Cs*zAxTQQV1Lz0cOwx-NooaTRPs9sWmOWAod0=',
            count: 77,
            percent: 0.54,
            media: ['AnRanEducation安然', 'A重磅实事追踪', 'CEO商业财经']
          },
          {
            opinion: '香港“反华暴乱”幕后黑手',
            rowkey: '20105849742540e2025e93a4054724',
            source_url: 'https://mp.weixin.qq.com/s/9uAeQ4-RRVx5-qTXhx8RTw',
            count: 71,
            percent: 0.5,
            media: ['Gudeby', '拾叁智库', 'IPO俱乐部']
          },
          {
            opinion: '香港近期暴乱分析',
            rowkey: '201058523637409e37b1bde2d8eeea',
            source_url: 'https://mp.weixin.qq.com/s/ygBZRVBFuEbDFJEUrQvnnQ',
            count: 56,
            percent: 0.4,
            media: ['深秋傍晚小雨', '中国档案报', '中纪反腐官网']
          }
        ],
        opinion_weixin_main_media: [
          {
            media: '环球网',
            count: 23,
            article: [
              {
                title: '这是我们在现场看到的真相！',
                rowkey: '2010583644417037f7b9b8f0733b82',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1563841868&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOxMc8b1esQ0Qz3-AGRvS9QXlLz-0LFtDaFrhRqgVcopGwT7W734aYx*Sfxj5JmjGagD0m7jhoC9A2KS-isXADwO5m2Gl1LE7171qQ9QcDbwwJ0cBiGbyhjUqFsXE2*oHZYs8*NmP1fcICIREa5ZeT9TUhAABgKd8QGTjVRfEfyOQ='
              },
              {
                title: '假如这帮人在英美，恐怕早就……',
                rowkey: '20105831615820b3fae18b2c0b8972',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564328334&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOxMc8b1esQ0Qz3-AGRvS9QW0G5MloFDALxANMjzYiiAuemb7ze2DuwrHTKWLzxRNMTfv1IFCB7tqHqxegieBAGn0mcH0C8YCi0DpNlnHVEx2vxr5OZpVb7uZpzQ1F-CgzW0LwAptr0JpkrJj-F6OvDIg9-ujLr*F0FYxyJcc78ho='
              },
              {
                title: '藏在香港示威者身边的美国人，是谁？',
                rowkey: '20105829522620bb9b49c260d5f3b0',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564541630&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOxMc8b1esQ0Qz3-AGRvS9QW0G5MloFDALxANMjzYiiAvFzy5mUVNLL8YjkO2tOA-WeaCnQyjrPd3pwTpQObgozWS-M8WgCnzde82NEh0yjwNghjs9EPvVYeoXTdq090bJzGP209ryP8lNJH8-RPIw2Z-JjjhyMDCPK4DAO5yTd4M='
              }
            ]
          },
          {
            media: '观察者网',
            count: 23,
            article: [
              {
                title: '香港的暴徒和自家政客的口水，英国人看不下去了',
                rowkey: '201058501433106ec67b7944a65105',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1562474312&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOmjAETQazk1TTYKGHFYPhSWn5k9cSiyos8n4cEuv*KZNsOHRoTs6YU*4d9mPRE05Jgqua8IpLhNpF*4XjzAAdIO3vR380coMTR2BRpWM2eqpYaR8qFITVKicIrpqFS6ix485rBL29O5*gppsUK0jsOyjjnL8LgFkeiE1X5gZzQEs='
              },
              {
                title: '是谁让香港青年误入歧途？',
                rowkey: '201058493183408355b8564f470b12',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1562562386&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOmjAETQazk1TTYKGHFYPhSWn5k9cSiyos8n4cEuv*KZOVhayNebpua4O*iHoR7k33GPD7cwreZnDAHp-kdPGKS4Y7UMnGOp3X1pDynsSHMDrL27kMxTAvteJhze6mQLLTIrR7J5fS0RD3zSBDiimMOMMIRmbXiLoz-ChU646ReHM='
              },
              {
                title: '突破底线！暴徒袭击香港警察情节升级',
                rowkey: '20105842828300b213036a7d7797b6',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1563203389&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOmjAETQazk1TTYKGHFYPhSYPUEXCQT8TH48XJiAL4QA3ay9CQ9Z1Ot9kUa575xm9ajv4PTlsd4m-7bbgrNEZGCcHRPuV5uEmExRWTUE3T-dZZY2cpjubyG3IMxjOdzxU6MiAiWwKjARGmofYagXEG4zereWjXUfXS7hByJR3Kh14='
              }
            ]
          },
          {
            media: '环球时报',
            count: 21,
            article: [
              {
                title: '胡锡进：香港乱了，北京该不该强力出手',
                rowkey: '20105836815890973fd569591a76f8',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1563884262&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO4vjOgB*VnqS7d3Fxu4gYFvsO957D9YKrBy6mGBjil*RZybrlYwOPwBBinsTfA8Ju5iiPnoRJdqOwub4uYw-GI27m06ePc1jZBPBEHyCJzEHaAySGH5cielRzCBkxdEpuqR62MauMbty0jc46rdogL7A-sLFZJq3iY8hKr5UEhNY='
              },
              {
                title: '香港市民哗然：国泰“暴动副机长”还能驾驶客机上天？',
                rowkey: '20105826921590e0a53a74fd2c4e42',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564822324&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO4vjOgB*VnqS7d3Fxu4gYFt8iB0x6gTA48O4B02fSN-Q1YRHIryy1i-a3xEVXSdORQjYEYumeaxboP7BQWLcjKgLPXhrsBmPO90uwvByGDMm*YL64GWGShFG7tX0kRyuzzUEfklZu4E13IptyiyNbiEj2DghrQv7f2Z9y7j2-GVg='
              },
              {
                title: '特朗普为啥突然"不理"香港？',
                rowkey: '20105826509380ab56e44eab3c6a9d',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564837032&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO4vjOgB*VnqS7d3Fxu4gYFt8iB0x6gTA48O4B02fSN-To4TpoMoOaR50V7-48exZEND9I0xXLqDtqSeI6XCn9ACK8ngzwsGNhbat9UbudIWs8G*SIl6aWu57R*mgBPbyWbHiry7d-kx8ZNbyxyE1oCCb3PY7ro9hAYsHfpD1RVnc='
              }
            ]
          },
          {
            media: '凤凰网',
            count: 6,
            article: [
              {
                title: '香港“4万公务员集会” 随机问20人18人假冒',
                rowkey: '2010582660077049ebb5217f357751',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564848830&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO9vdzQLxl3X-NgJikS9G0GSy9EWpm6vFfZuri6P6wB5lJxFHJkZz*G8Bv6gODGhkLyg7kDS3wng8OmSlDSQ3TstLQhlZ6*scqm-8z2YuFAqym8rxCc9JYy3vyFhbxf79J1Rulbkkxx1bHLq2GuGg9Ahd1mHpsAX312ik*sDtn4eg='
              },
              {
                title: '董建华：香港正面临前所未有风暴 幕后黑手是美台',
                rowkey: '20105829051060c9fdd7cb179c6318',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564589439&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO9vdzQLxl3X-NgJikS9G0GSy9EWpm6vFfZuri6P6wB5kfbaFMJ0PBdo83wpBqszzJia9hfcNOdGnDEu0JakT4gbKIcpkneG07olZ-a6sLWK5yhbSHofn7S3kIFCOMydzLIugplBaAd726VETTiuRMuxcPwkKJgSHUKyCyxQYOBmk='
              },
              {
                title: '香港的暴徒和自家政客的口水，英国人看不下去了',
                rowkey: '2010584981191032bfd0deb6c2e3e5',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1562526972&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAO9vdzQLxl3X-NgJikS9G0GV6I1wbv5uXhUHrHfYjArZaI8Sv3oFlqi46LXfBRP72fgU9Pfx38qNythW0zk2hk*PtkIGKVReY2*V16PH1wgMBqCUjDF*rht4C5FIXWpSHrYABJo-FG-sD8adW-rakZKKWc3y4C30Q9JDQodTsKGfM='
              }
            ]
          },
          {
            media: '港澳行',
            count: 6,
            article: [
              {
                title: '来围观：看当年“皇家”警察如何处置“和平”示威者！',
                rowkey: '2010583644417037f7b9b8f0733b82',
                source_url: 'https://mp.weixin.qq.com/s/nidPhtYVXoyJynV0ExVtWw'
              },
              {
                title: '“港獨”有“毒”！',
                rowkey: '20105831615820b3fae18b2c0b8972',
                source_url: 'https://mp.weixin.qq.com/s/o6UMh_9TJqLZiDk6GeWK0w'
              },
              {
                title: '视频:无法无天！竟然将国旗丢到海里',
                rowkey: '20105829522620bb9b49c260d5f3b0',
                source_url: 'https://mp.weixin.qq.com/s/8dcSP-wLDCMfQC4mN89i2Q'
              }
            ]
          },
          {
            media: '北京日报',
            count: 6,
            article: [
              {
                title: '警员手指被暴徒生生咬断！香港以法治乱势在必行',
                rowkey: '201058424675908b762e964c387c1c',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1563250679&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOaQOzHm2QGSXRpAwB17RiU3k-MNFKSIA8D7kg8epS87ijZHpVaj2ajXjacX1oGLm6plhlGTBTw6nVIgFAdTjFP1erN1c3drKqjo3e9vPNkbQ1-PSF7uMUjs8C90ywPF5FGuK6ePdWTpT9x7*K*c4KcuZb3nyAA86v*Lqpaahwkvo='
              },
              {
                title: '锐评：让民族败类无路可走！',
                rowkey: '20105825902200da9c774200c91d65',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564931687&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOaQOzHm2QGSXRpAwB17RiU04k1XKNlQ-830o1iS1V9Tf4qSAowJ*nKrbzMITrIoBO*qC*YMa8*7ocjqnCMd2dKx-j7i-h1Cxpg62Sj-XhAiQWzsrHo-SlUPyN8qE69p8pFHzaUd0AGFs3vBDc4XWVU5afELYlRgkG4*Mr3vc*XfE='
              },
              {
                title: '触目惊心！“港独”头目被拘后，港警竟从“武器库”搜出……',
                rowkey: '201058265212103bff39e7e3350a27',
                source_url:
                  'https://weixin.sogou.com/api/share?timestamp=1564845359&signature=qIbwY*nI6KU9tBso4VCd8lYSesxOYgLcHX5tlbqlMR8N6flDHs4LLcFgRw7FjTAOaQOzHm2QGSXRpAwB17RiU04k1XKNlQ-830o1iS1V9TfkJazLTixq-YcmdeG9IwN4Cky9Y8Icq3wbh6iFccpIj72coQG7ymNjCqFb1R-5zV229ek7K-2d5auwEDQzo6GW45vqVIkdSLEYA4gjV2EENxgGgXuSBxz0KtMX4UvCkj8='
              }
            ]
          }
        ]
      },
      loading: true,
      created: ''
    }
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      let _this = this
      window.onscroll = function() {
        let height = document.documentElement.scrollTop + 75
        if (height > 200) {
          $('#emotion-tab').addClass('scroll-tab')
          $('#emotion-tab').addClass('scroll')
        } else {
          $('#emotion-tab').removeClass('scroll')
          $('#emotion-tab').removeClass('scroll-tab')
        }
        let b = window.document.getElementById('zfgd').offsetTop
        let c = window.document.getElementById('wbgd').offsetTop
        let d = window.document.getElementById('wxgd').offsetTop - 300
        if (height < b) {
          _this.switchTab(0)
        } else if (height >= b && height < c) {
          _this.switchTab(1)
        } else if (height >= c && height < d) {
          _this.switchTab(2)
        } else {
          _this.switchTab(3)
        }
      }
    })
  },
  methods: {
    switchTab(index, scrollTo = false) {
      if (scrollTo) {
        var h = 0
        switch (index) {
          case 0:
            document.documentElement.scrollTop = h
            break
          case 1:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('zfgd').offsetTop - h
            break
          case 2:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('wbgd').offsetTop - h
            break
          case 3:
            h = document.documentElement.scrollTop === 0 ? 140 : 75
            document.documentElement.scrollTop =
              window.document.getElementById('wxgd').offsetTop - h
            break
        }
      }
      $('.flex-container a').removeClass('selected')
      $('.flex-container a:eq(' + index + ')').addClass('selected')
    },
    loadData() {
      this.title = '香港暴乱'
      this.loading = false
      this.created = '2019-07-30 18:55:29'
      this.$nextTick(() => {
        this.createChart()
      })
    },
    createChart() {
      let loopKeys = [
        'state_sum',
        'domestic_dis',
        'oversea_dis',
        'opinion_gov_dis',
        'opinion_gov_main',
        'opinion_weibo_forward',
        'opinion_weibo_usertype_list'
      ]
      for (let i = 0; i < loopKeys.length; i++) {
        let key = loopKeys[i]
        let data = this.data[key]
        this.loopChart(data, key)
      }
      if (this.data['opinion_domestic_media_chronological'].isIncubation) {
        this.eventTrend('opinion_domestic_media_chronological')
      }
    },
    eventTrend(id) {
      let data = this.data['opinion_domestic_media_chronological'].data.trend
      let keys = []
      let seriesData = []
      data.map(item => {
        keys.push(item.date)
        if (item.points) {
          seriesData.push({
            value: item.count,
            symbol: 'image://' + findPointImg(item.points[0]),
            name: item.points.join(',')
          })
        } else {
          seriesData.push({
            value: item.count,
            name: ''
          })
        }
      })
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            stack: '总量',
            symbol: 'none',
            symbolSize: 20,
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function(params) {
                  return params.data.name
                }
              }
            },
            data: seriesData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    loopChart(data, id) {
      // ["#83d944","#3178d2","#568efc","#8abaf8","#f0a142","#e7b42a","#ecc355","#ecc355"]
      var colors = ['#FC5D73', '#555DFE']
      var name = null
      var legend = []
      var chartData = []
      var rateMap = []
      switch (id) {
        case 'state_sum':
          name = '媒体类型分布'
          colors = ['#ff9587', '#fcd41a', '#62affe', '#74ccb1', '#76d4ff', '#2a38d8', '#ffb146', '#66cb03', ]
          legend = ["微博", "评论", "自媒体号", "网媒", "客户端", "论坛", "视频", "政务",]
          chartData = [
           {name: "微博", value: data.weibo},
            {name: "评论",value: data.pinglun},
            {name: "自媒体号", value: data.zimeitihao},
            {name: "网媒", value: data.wangmei},
            {name: "客户端", value: data.kehuduan},
            {name: "论坛", value: data.luntan},
            {name: "视频", value: data.shipin},
            {name: "政务", value: data.zhengwu}
          ]
          var total = parseInt(data.weibo)+parseInt(data.pinglun)+parseInt(data.zimeitihao)+parseInt(data.wangmei)+parseInt(data.kehuduan)+parseInt(data.luntan)+parseInt(data.shipin)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.weibo / total) * 100).toFixed(2),
                  ((data.pinglun / total) * 100).toFixed(2),
                  ((data.zimeitihao / total) * 100).toFixed(2),
                  ((data.wangmei / total) * 100).toFixed(2),
                  ((data.kehuduan / total) * 100).toFixed(2),
                  ((data.luntan / total) * 100).toFixed(2),
                  ((data.shipin / total) * 100).toFixed(2),
                  ((data.zhengwu / total) * 100).toFixed(2)
                ]
          break
        case 'domestic_dis':
          name = '国内媒体观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'oversea_dis':
          name = '国外媒体观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_gov_dis':
          name = '政府观点情绪分布'
          legend = ['负面', '正面']
          chartData = [
            { name: '负面', value: data.negative },
            { name: '正面', value: data.positive }
          ]
          var total = parseInt(data.negative) + parseInt(data.positive)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.negative / total) * 100).toFixed(2),
                  ((data.positive / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_gov_main':
          name = '主要政府观点来源分布'
          colors = [
            '#3479CF',
            '#F1A64F',
            '#82BBF5',
            '#4A9D67',
            '#55D9FF',
            '#67A5FF',
            '#74CCB1',
            '#EDCE05',
            '#EE8131',
            '#D94928'
          ]
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            if (total == 0) {
              rateMap.push(0)
            } else {
              rateMap.push(((data.data[i] / total) * 100).toFixed(2))
            }
          }
          break
        case 'opinion_weibo_forward':
          name = '微博类型观点分布'
          colors = ['#F2A64F', '#26A2F9']
          legend = ['转发', '原文']
          chartData = [
            { name: '转发', value: data.forward },
            { name: '原文', value: data.original }
          ]
          var total = parseInt(data.forward) + parseInt(data.original)
          rateMap =
            total == 0
              ? [0, 0]
              : [
                  ((data.forward / total) * 100).toFixed(2),
                  ((data.original / total) * 100).toFixed(2)
                ]
          break
        case 'opinion_weibo_usertype_list':
          name = '微博用户观点分布'
          colors = []
          legend = data.type
          var total = 0
          for (let i = 0; i < legend.length; i++) {
            total += data.data[i]
            chartData.push({ name: legend[i], value: data.data[i] })
          }
          for (let i = 0; i < data.data.length; i++) {
            if (total == 0) {
              rateMap.push(0)
            } else {
              rateMap.push(((data.data[i] / total) * 100).toFixed(2))
            }
          }
          legend = legend.map((item, index) => {
            let temp = {
              name: item,
              textStyle: {
                color: '#ffa237'
              },
              icon: 'image:///static/img/v_red.png'
            }
            switch (item) {
              case '金V':
                colors.push('#c91d10')
                temp.textStyle.color = '#c91d10'
                temp.icon = 'image:///static/img/v_red.png'
                break
              case '黄V':
                colors.push('#fea700')
                temp.textStyle.color = '#fea700'
                temp.icon = 'image:///static/img/v_orange.png'
                break
              case '蓝V':
                colors.push('#09aaf7')
                temp.textStyle.color = '#09aaf7'
                temp.icon = 'image:///static/img/v_blue.png'
                break
              case '微博女郎':
                colors.push('#fc5d9a')
                temp.textStyle.color = '#fc5d9a'
                temp.icon = 'image:///static/img/v_pink.png'
                break
              case '达人':
                colors.push('#e8220e')
                temp.textStyle.color = '#e8220e'
                temp.icon = 'image:///static/img/v_talent_show.png'
                break
              default:
                colors.push('#0078d5')
                temp.textStyle.color = '#0078d5'
                temp.icon = 'circle'
                break
            }
            return temp
          })
          break
      }
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c}({d}%)'
        },
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'vertical',
          x: 'left',
          bottom: '0',
          data: legend,
          icon: 'circle',
          itemGap: 5,
          formatter: function(name) {
            let legendMap = []
            _(legend).forEach((item, index) => {
              if (typeof item == 'string') {
                legendMap.push(item)
              } else if (typeof item == 'object') {
                legendMap.push(item.name)
              }
            })
            let index = legendMap.indexOf(name)
            return name + ' ' + rateMap[index] + '%'
          }
        },
        color: colors,
        series: [
          {
            name: name,
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: chartData
          }
        ]
      }
      let element = window.document.getElementById(id)
      let chart = globalCreateChart(element, option)
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    findPointImg(name) {
      return findPointImg(name)
    }
  }
}
</script>
<style scoped>
.fxbg.pt_0 {
  padding-top: 0;
}
/*flex-container */
.btn_tab {
  margin: auto;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 73px;
  border: none;
  font-size: 14px;
}
.btn_tab a.selected {
  color: #fff;
  background: rgba(42, 103, 179, 1);
}
.scroll-tab {
  position: fixed;
  z-index: 3000;
  left: auto;
  right: auto;
  top: 200px;
  margin: auto;
}
.scroll {
  top: 0px;
}
.scroll .fxbg {
  box-shadow: 0px 3px 13px 0px rgba(194, 194, 194, 0.2);
  padding: 10px 0;
}
.btn_tab a {
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: rgba(237, 237, 237, 0.6);
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  display: block;
  padding: 0 95px;
  border: none;
}
.echarts_empty {
  height: 250px;
}
/*国内媒体观点*/
.gnmtgd .mod-list-title1 {
  width: 350px;
}
.gnmtgd .source {
  width: 475px;
  float: left;
}
.gnmtgd .source a {
  width: 174px;
  float: left;
}
.gnmtgd .source var {
  font-style: normal;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  float: left;
}
.gnmtgd .source span {
  padding: 0 20px;
  display: inline-block;
  color: rgba(180, 180, 180, 0.6);
  float: right;
}
.gnmtgd .source a:last-child {
  width: 127px;
}
.gnmtgd .source a:last-child span {
  display: none;
}
/*时间轴*/
.demo {
  width: 1116px;
  margin: 20px auto;
  padding: 45px 0 0;
}
.main-timeline {
  position: relative;
}
.main-timeline:before,
.main-timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline:before {
  content: '';
  width: 3px;
  height: calc(100% - 50px);
  background: #bebebe;
  position: absolute;
  top: 0;
  left: 50%;
}
.main-timeline .timeline {
  width: 50%;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-timeline .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 7px;
}
.main-timeline .circle {
  width: 469px;
  height: 90px;
  background: rgb(239, 244, 254);
  border-radius: 8px;
  float: left;
  position: relative;
  margin-top: -35px;
}
.main-timeline .content {
  display: table;
  padding: 0 5px;
  position: relative;
}
.main-timeline .year {
  display: block;
  margin: 0 0 50px 0;
  border-radius: 7px;
  font-size: 12px;
  color: #fff;
  width: 77px;
  height: 19px;
  background-color: #2a67b3;
  border-radius: 4.5px;
  margin-top: -15px;
}
.main-timeline .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
}
.main-timeline .timeline:first-child .timeline-content:before {
  top: 7px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
  float: right;
}
.main-timeline .timeline:nth-child(even) {
  margin-top: 60px;
}
.main-timeline .timeline:nth-child(even) .timeline-content:before {
  top: 3px;
}
.main-timeline .timeline:nth-child(even) .year {
  margin-top: -20px;
}
.main-timeline .timeline:nth-child(even) .icon {
  left: -10px;
  right: 0;
}
.main-timeline h5,
.main-timeline .circle p {
  line-height: 24px;
  text-align: left;
  font-size: 12px;
  padding: 0 10px;
  height: 24px;
  overflow: hidden;
  color: rgb(71, 71, 71);
}
.main-timeline h5 {
  margin-bottom: 0;
  font-weight: 600;
  margin-top: 10px;
}
.main-timeline .circle .xgbds span {
  padding-right: 15px;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source var {
  font-style: normal;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source span {
  padding: 0 8px;
  color: rgb(71, 71, 71);
}
.main-timeline .circle .source a:last-child span {
  display: none;
}
/*txt_list*/
.txt_list .mtgd_je {
  background: rgba(230, 230, 230, 0.4);
  padding: 10px;
  border-radius: 6px;
}
.mtgd_je ul {
  height: 90px;
}
.txt_list h5,
.txt_list li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  line-height: 30px;
}
.txt_list h5 a {
  color: rgb(63, 144, 247);
  font-weight: 600;
}
.txt_list p {
  line-height: 30px;
  font-size: 12px;
}
.txt_list p span {
  color: #7d7d7d;
}
.txt_list li {
  border-bottom: 1px solid rgba(134, 134, 134, 0.2);
  font-size: 12px;
}
.txt_list li:last-child {
  border: none;
}
/*微博原文观点*/
.picture_list li {
  line-height: 40px;
  margin-bottom: 10px;
  height: auto;
}
.picture_list .mod-list-title2 {
  width: 370px;
  float: left;
  padding-right: 10px;
}
.picture_list a.mod-list-title2 {
  line-height: 20px;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  margin-top: 7px;
}
.picture_list .xgwbs {
  width: 100px;
  float: left;
  font-size: 12px;
  color: #000;
  text-align: center;
}
.picture_list .dbyh {
  width: 480px;
  text-align: left;
  float: left;
  color: #000;
}
.picture_list dl {
  width: 480px;
  float: left;
}
.picture_list dd {
  width: 160px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  float: left;
}
.picture_list dd .img,
.txt_list .img {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
}
.txt_list .img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.picture_list .img img,
.txt_list .img img {
  border-radius: 50%;
}
.picture_list .img .ico,
.txt_list .img .ico {
  width: 16px;
  height: 16px;
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.picture_list dd .name {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}
.txt_list .echarts_empty .img {
  width: 94px;
  height: 120px;
  margin: 0;
}
/*事件关键时间点*/
.demo2 {
  width: 1120px;
  margin: 30px auto 0;
  padding: 0;
  position: relative;
}
.demo2:before {
  content: '';
  width: 3px;
  background: #bebebe;
  position: absolute;
  top: 40px;
  left: 10%;
  bottom: 46px;
  height: calc(100% - 86px);
}
.main-timeline2 {
  position: relative;
}
.main-timeline2 .timeline {
  width: 90%;
  float: right;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
}
.main-timeline2 .timeline:before,
.main-timeline2 .timeline:after {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}
.main-title {
  width: 10%;
  float: left;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.main-title .point {
  height: 70px;
  overflow: hidden;
}
.main-title p {
  line-height: 24px;
  overflow: hidden;
  font-weight: 600;
}
.main-title p span {
  font-size: 14px;
}
.main-title p .img {
  margin-right: 8px;
  vertical-align: middle;
}
.main-title h3 {
  width: 36px;
  height: 123px;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(199, 199, 199, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 18px;
  font-size: 20px;
  color: rgba(0, 103, 177, 1);
  line-height: 30px;
  text-align: center;
  padding-top: 13px;
  margin-left: 25px;
}
.main-timeline2 .timeline:last-child,
.main-timeline2 .main-title:nth-last-child(2) {
  padding-bottom: 0;
}
.main-timeline2 .timeline-content {
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.main-timeline2 .timeline-content:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #bebebe;
  position: absolute;
  left: 0;
  z-index: -1;
  top: 45px;
}
.main-timeline2 .circle {
  width: 830px;
  float: right;
  position: relative;
  text-align: left;
  overflow: hidden;
}
.main-timeline2 .circle_title,
.main-timeline2 .mod-list {
  background: rgb(239, 244, 254);
  border-radius: 8px;
}
.main-timeline2 .circle_title {
  line-height: 25px;
  padding: 8px 15px;
}
.main-timeline2 .circle_title h4,
.main-timeline2 .circle_title p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}
.main-timeline2 .circle_title p span {
  padding-right: 10px;
}
.main-timeline2 .circle_title p .bd {
  padding-left: 10px;
}
.main-timeline2 .circle_title dl {
  overflow: hidden;
  margin-bottom: 0;
}
.main-timeline2 .circle_title dd,
.main-timeline2 .circle_title a,
.main-timeline2 .circle_title .bd {
  float: left;
  font-size: 12px;
  color: #474747;
}
.main-timeline2 .circle_title .bd {
  padding: 0 15px;
}
.main-timeline2 .circle_title dd:last-child > .bd {
  display: none;
}
.main-timeline2 .circle_title h4 a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 740px;
}
.main-timeline2 .circle .mod-list {
  padding: 10px 15px;
  height: auto;
}
.main-timeline2 .circle .mod-list li {
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}
.main-timeline2 .mod-list li a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.main-timeline2 .mod-list li .mod-list-title2 {
  width: 680px;
}
.main-timeline2 .mod-list li .site {
  width: 80px;
  padding-left: 10px;
  color: #474747;
  float: left;
}
.main-timeline2 .site span {
  padding-right: 10px;
}
.main-timeline2 .content {
  display: table;
  padding: 0 5px;
  position: relative;
  width: auto;
  margin: 0;
}
.main-timeline2 .year {
  display: block;
  margin: 0 0 0 27px;
  border-radius: 7px;
  font-size: 14px;
  color: #fff;
  width: 112px;
  height: 38px;
  background-color: #2a67b3;
  border-radius: 8px;
  margin-top: 2px;
  line-height: 38px;
}
.main-timeline2 .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #2a67b3;
  position: absolute;
  top: 38px;
  left: -10px;
  display: inline-block;
}
.main-timeline2 .item {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.demo2 .item:nth-child(2n) .timeline-content:before {
  display: none;
}
.demo2 .item:nth-child(2n) .main-title {
  margin-top: 10px;
}
.main-timeline2 .bd_n:before {
  display: none;
}
.item_title .main-title {
  margin-top: 0;
}
.main-title p img {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
</style>